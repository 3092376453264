
import React, { Component } from "react";
import ImgCard from '../Components/ImgCard';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';


import { tm, md, as, cc } from '../Images/Team';

import '../Stylesheets/TeamPage.scss';

class TeamPage extends Component {
    state = {
        openId: 0
    };

    selectOpen = openId => {
        this.setState({ openId });
    };
    render() {
        return (
            <div className="contentDiv teamPageContent">
                <div className="column">
                    <h2 title="Project Leaders">Project Leaders</h2>
                    <hr />
                </div>
                <div className="row">
                    <hr />
                    <div className="cardholder column">
                        <Accordion defaultActiveKey="0">
                            <Card className="tmcd">
                                <Accordion.Toggle as={Card.Header} eventKey="0"
                                    accordionid={0}
                                    open={this.state.openedId}
                                    onClick={this.selectOpen}>
                                    Terrence Martell
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body><ImgCard cl="teamCard" name="Terrence Martell" message="
Terrence F. Martell, Director of the Weissman Center for International Business at Baruch College/CUNY, received his BA in Economics from Iona College and his Ph.D. in Finance from the Pennsylvania State University. He oversees a myriad of international programs and projects at the Weissman Center, and is also the Saxe Distinguished Professor of Finance. His particular area of expertise is international commodity markets, in which he teaches and conducts research.

Terrence Martell is chairman of ICE Clear US, the US clearing house for ICE’s futures and options trading, a director of ICE Clear Credit, where he chairs its Risk Committee and vice chair of ICE Futures U.S. He is also chairman of the Board of VVC, an exploration and production company that is listed on the Toronto Stock Exchange.
" pic={tm} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="tmcd">
                                <Accordion.Toggle as={Card.Header} eventKey="3"
                                    accordionid={3}
                                    open={this.state.openedId}
                                    onClick={this.selectOpen}>
                                    Costanza Consolandi
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body><ImgCard cl="teamCard" name="Costanza Consolandi" message="Costanza Consolandi, PhD is Associate Professor of Corporate Finance at the University of Siena, where she teaches undergraduate courses in corporate finance and graduate courses in corporate valuation. 
She is member of the Steering Committee of the MSc in Finance and member of the University Board of Directors.  She is external SDG expert for KKS Advisors since 2019 and consultant for the investment area of MAPFRE, the largest Spanish insurance group, since 2017. 
In the last years, her research has focused primarily on finance and sustainability."
                                        footerItems={<div className="teamCardFooter">
                                            <a target="_blank" href="https://twitter.com/costanzacons" rel="noreferrer"><FontAwesomeIcon icon={faTwitterSquare} size="2x" /></a>
                                            <a target="_blank" href="https://www.linkedin.com/in/costanza-consolandi-34821319/" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} size="2x" /></a></div>} pic={cc} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="tmcd">
                                <Accordion.Toggle as={Card.Header} eventKey="1"
                                    accordionid={1}
                                    open={this.state.openedId}
                                    onClick={this.selectOpen}>
                                    Mert Demir
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body><ImgCard cl="teamCard" name="Mert Demir" message="Mert Demir is the Director of Research of the CSR-Sustainability Monitor project at the Weissman Center for International Business, Baruch College/CUNY. Demir’s research centers around corporate finance, valuation, reporting, and corporate responsibility and sustainable business. Demir holds a Ph.D. in Finance from The Zicklin School of Business, Baruch College/CUNY and an MBA from the Middle East Technical University, Turkey. His research has been published in top tier academic journals and presented in several national and international conferences. Demir is also an editor of the Monitor’s annual report." pic={md} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="tmcd">
                                <Accordion.Toggle as={Card.Header} eventKey="2"
                                    accordionid={2}
                                    open={this.state.openedId}
                                    onClick={this.selectOpen}>
                                    Alex Schwarz
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body><ImgCard cl="teamCard" name="Alex Schwarz" message="Alex Schwarz is the Lead Analyst of the CSR-Sustainability Monitor® project at the Weissman Center for International Business, Baruch College/CUNY. After working on iterations of the Monitor for more than eight years, he now manages the team of graduate student analysts collecting data for the project, and assists with research endeavors utilizing that data. He holds a BSBA in Organization and Human Resource Management from Washington University in St. Louis." pic={as} /></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <hr />

                    </div>
                </div>
                <div className="col-sm">
                    <h2>Analysts:</h2><hr />
                    <div className="team_analystsHolder">
                        <ul className="list-group"><li className="list-group-item">Laura Agosto</li>
                        </ul><h4>&nbsp;</h4>
                    </div>
                </div>
            </div>
        )
    }
}

export default TeamPage;