import React, { Component } from "react";
import Card from "react-bootstrap/Card";

import globeIcon from "../Images/Icons/earth icon black.png";
import fortune500 from "../Images/Icons/Fortune 500 icon black.png";
import envTopics from "../Images/Icons/CSR-S environment black icon.png";
import sdgTargets from "../Images/Icons/SDG targets black icon.png";

import "../Stylesheets/ProjectHighlights.scss";

class ProjectHighlights extends Component {
    render() {
        return (
            <section>
                <h3>Project Highlights</h3>
                <hr />
                <div className="proHighlightsContainer">
                    <Card className="proHighlights">
                        <div className="row no-gutters">
                            <div className="col-md-1">
                                <Card.Img className="prHi_iconImg" variant="top" src={fortune500} />
                            </div>
                            <div className="col-md-8">
                                <Card.Body>
                                    <Card.Title>324 Fortune Global 500 companies scored</Card.Title>
                                    <Card.Text>
                                        Our analysts scored all reports published by Fortune Global 500 companies in 2017, the year following the SDGs coming into effect
                        </Card.Text>
                                </Card.Body>
                            </div>
                        </div>
                    </Card>
                    <Card className="proHighlights">
                        <div className="row no-gutters">
                            <div className="col-md-1">
                                <Card.Img className="prHi_iconImg" variant="top" src={globeIcon} />
                            </div>
                            <div className="col-md-8">
                                <Card.Body>
                                    <Card.Title>8 Regions, 30 Countries, 17 Industries, $27.6 trillion in total revenues </Card.Title>
                                    <Card.Text>
                                        Our sample consists of the world’s largest enterprises across all regions and industries
                        </Card.Text>
                                </Card.Body>
                            </div>
                        </div>
                    </Card>
                    <Card className="proHighlights">
                        <div className="row no-gutters">
                            <div className="col-md-1">
                                <Card.Img className="prHi_iconImg" variant="top" src={sdgTargets} />
                            </div>
                            <div className="col-md-8">
                                <Card.Body>
                                    <Card.Title>46 Environmental SDG targets mapped</Card.Title>
                                    <Card.Text>
                                        Starting from the UNEP’s list, we identified 46 targets that we could tie to one of the CSR-S Monitor environment topics
                        </Card.Text>
                                </Card.Body>
                            </div>
                        </div>
                    </Card>
                    <Card className="proHighlights">
                        <div className="row no-gutters">
                            <div className="col-md-1">
                                <Card.Img className="prHi_iconImg" variant="top" src={envTopics} />
                            </div>
                            <div className="col-md-8">
                                <Card.Body>
                                    <Card.Title>15 CSR-S Monitor environment topics covered</Card.Title>
                                    <Card.Text>
                                        Out of 21 environment topics the CSR-S Monitor looks at, the SDG targets reference 15 at least once
                        </Card.Text>
                                </Card.Body>
                            </div>
                        </div>
                    </Card>
                    <br />
                </div>
            </section>
        );
    }
}
export default ProjectHighlights;