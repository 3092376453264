import React, { Component } from "react";
import ZickLogo from '../Images/BaruchZicklinBlueLogo.png';
import CunyLogo from '../Images/CUNY_Logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

import '../Stylesheets/Footer.scss';


class Footer extends Component {
    render() {
        return (
            <section className="footer">
                <div className="row no-gutters">
                    <span className="text-muted"><a href="https://zicklin.baruch.cuny.edu/centers/weissman/" target="_blank" rel="noreferrer"><img className="img-responsive footerLogo" src={ZickLogo} alt="Baruch, Zicklin, WCIB Logo" /></a></span><div className="teamCardFooter">
                        Follow us on social media:
                        <a target="_blank" href="https://twitter.com/wcibatbaruch" rel="noreferrer"><FontAwesomeIcon icon={faTwitterSquare} size="2x" /></a>
                        <a target="_blank" href="https://www.facebook.com/WeissmanCenterforInternationalBusiness/" rel="noreferrer"><FontAwesomeIcon icon={faFacebookSquare} size="2x" /></a></div>

                    <a href="https://www.cuny.edu/" target="_blank" rel="noreferrer"><img className="img-responsive footerCunyLogo" src={CunyLogo} alt="CUNY Logo" /></a>
                </div>
                <div className="text-muted footerCopyright">Copyright DevCrafted 2020</div>

            </section>
        );
    }
}

export default Footer;