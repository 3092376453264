import React, { Component } from 'react';
import * as d3 from "d3";
import { select } from 'd3-selection';
// eslint-disable-next-line
import transition from 'd3-transition';
import DataCSV from '../CsvData/ComboChart.csv';
//import SdgGoal from '../Components/SdgGoal';
//import { Sdg1, Sdg2, Sdg3, Sdg6, Sdg7, Sdg8, Sdg9, Sdg10, Sdg11, Sdg12, Sdg13, Sdg14, Sdg15 } from '../Images/SDG';

import '../Stylesheets/D3Charts.scss';

//const goalArr = { "SDG1": Sdg1, "SDG2": Sdg2, "SDG3": Sdg3, "SDG6": Sdg6, "SDG7": Sdg7, "SDG8": Sdg8, "SDG9": Sdg9, "SDG10": Sdg10, "SDG11": Sdg11, "SDG12": Sdg12, "SDG13": Sdg13, "SDG14": Sdg14, "SDG15": Sdg15, "OVERALL": '' };

class D3BarChart extends Component {
    componentDidMount() {
        this.DrawChart();
    }
    DrawChart = (data) => {
        //------------------------SVG PREPARATION------------------------//
        // var width = this.props.width;
        // var height = this.props.height;
        var width = window.innerWidth;
        var height = width * .5;

        var marg = width * .07;
        var adj = marg * 2.2;
        var offset = adj * .3;

        select("#barChart").append("div")
            .attr("class", "barchart_tooltip")
            .style("opacity", 0);

        // we are appending SVG first
        var svg = d3.select("div#barChart").append("svg")
            .attr("viewBox", "-" + (offset * 3) + " -" + offset + " " + (width + adj * 3.2) + " " + (height + marg * 2))
            .attr("width", "100%")
            .classed("svg-content", true);
        //add legend box
        var x_bywidth = (width + adj);
        svg.append("rect").attr("x", x_bywidth - marg).attr("y", 0).attr("width", "20px").attr("height", "20px").style("fill", "#749A4B");
        svg.append("line").attr("x1", x_bywidth - marg).attr("y1", 40).attr("x2", x_bywidth - marg + 20).attr("y2", 40).style("stroke", "#00739A").style("stroke-width", "3");
        svg.append("text").attr("x", x_bywidth - marg + 25).attr("y", 7.5).text("Average").attr("class", "legendBoxText").attr("alignment-baseline", "middle");
        svg.append("text").attr("x", x_bywidth - marg + 25).attr("y", 40).text("Std. Deviation").attr("class", "legendBoxText").attr("alignment-baseline", "middle");

        var dataset = d3.csv(DataCSV);
        dataset.then(function (data) {
            data.map(function (d) {
                d.Average = +d.Average;
                d.StdDev = +d.StdDev;
                return d;
            });
        });
        //scales
        var xScale = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(.1);
        var yScale = d3.scaleLinear()
            .rangeRound([height, 0]);
        var y2Scale = d3.scaleLinear()
            .rangeRound([height, 0]);


        //data prep 
        dataset.then(function (data) {
            xScale.domain(data.map(function (d) {
                return d.Goal;
            }))
            yScale.domain([0, 100])
            y2Scale.domain([0, 1]);
        });
        //adding axes
        dataset.then(function (data) {
            // var xAxis = d3.axisBottom(xScale).tickFormat((d, i) => {
            //     if (d !== "OVERALL") { return <SdgGoal cl="d3Bar_icons" src={goalArr[d]} /> } else {
            //         return "Overall";
            //     }
            // });
            svg.append("g")
                .attr("class", "axis")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(xScale))
                .selectAll("text")
                .attr("class", "d3bar_ticks")
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-35)");;

            svg.append("g")
                .attr("class", "axis")
                .text("↑ Average")
                .call(d3.axisLeft(yScale).tickFormat((d, i) => { return d + "%" }));

            svg.append("g")
                .attr("class", "axis")
                .text("↑ Standard Deviation")
                .attr("transform", "translate(" + (width) + ",0)")
                .call(d3.axisRight(y2Scale));
            svg.append("g")
                .attr("class", "grid")
                .call(d3.axisLeft(yScale)
                    .tickSize(-width)
                    .tickFormat("")
                );

            svg.append("text")
                .attr("class", "d3barAxisLabl")
                .attr("transform", "rotate(-90)")
                .attr("y", (-(offset * 1.8)))
                .attr("x", 0 - (height / 1.8))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("Average envSDG Score");
            svg.append("text")
                .attr("class", "d3barAxisLabl")
                .attr("transform", "rotate(-90)")
                .attr("y", width + offset * 1.2)
                .attr("x", 0 - (height / 1.8))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("Standard Deviation");

        });
        // creating bars
        var _barWidth = 0;
        dataset.then(function (data) {
            svg.selectAll("div")
                .data(data)
                .enter()
                .append("rect")
                .attr("class", "comboBar")
                .attr("x", function (d) {
                    return xScale(d.Goal);
                })
                .attr("y", function (d) {
                    return yScale((d.Average * 100));
                })
                .attr("width", function (d) { _barWidth = xScale.bandwidth(); return xScale.bandwidth(); })
                .attr("height", function (d) {
                    return height - yScale((d.Average * 100));
                })
                .on("mouseover", (i, d) => {
                })
                .on("mousemove", (i, d) => {
                    select('.barchart_tooltip').transition()
                        .duration(50)
                        .style("opacity", 1);
                    select('.barchart_tooltip').html("<p>" + d.Goal + "</p> <p>Average: <span class='d3bar_ttip_value'>" + (d.Average * 100).toFixed(2) + "%</span></p>")
                        .style("left", (i.pageX + 10) + "px")
                        .style("top", (i.pageY + 10) + "px");
                })
                .on("mouseout", () => {
                    select('.barchart_tooltip').transition()
                        .duration(50)
                        .style("opacity", 0);
                })
        });
        //Create line chart

        // d3's line generator
        dataset.then(function (data) {
            var line = d3.line()
                .x(function (d) { return xScale(d.Goal) + (_barWidth / 2); }) // set the x values for the line generator
                .y(function (d) { return y2Scale(d.StdDev); }) // set the y values for the line generator 
                .curve(d3.curveMonotoneX) // apply smoothing to the line
            svg.append("path")
                .datum(data) // 10. Binds data to the line 
                .attr("class", "comboLine") // Assign a class for styling 
                .attr("d", line); // 11. Calls the line generator 

            // 12. Appends a circle for each datapoint 
            svg.selectAll(".dot")
                .data(data)
                .enter().append("circle") // Uses the enter().append() method
                .attr("class", "dot") // Assign a class for styling
                .attr("cx", function (d) { return (xScale(d.Goal) + (_barWidth / 2)) })
                .attr("cy", function (d) { return y2Scale(d.StdDev) })
                .attr("r", 8)
                .on("mousemove", (i, d) => {
                    select('.barchart_tooltip').transition()
                        .duration(50)
                        .style("opacity", 1);
                    select('.barchart_tooltip').html("<p>" + d.Goal + "</p> <p>StdDev: <span class='d3bar_ttip_value'>" + (d.StdDev).toFixed(2) + "</span></p>")
                        .style("left", (i.pageX + 10) + "px")
                        .style("top", (i.pageY + 10) + "px");
                })
                .on("mouseout", () => {
                    select('.barchart_tooltip').transition()
                        .duration(50)
                        .style("opacity", 0);
                })
        });
    }

    render() {
        return (<div id="barChart"></div>);
    }
}


export default D3BarChart;