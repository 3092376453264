import React, { Component } from "react";
import Card from 'react-bootstrap/Card';
import '../Stylesheets/Components/ImgCard.scss';


class ImgCard extends Component {
    render() {
        return (
            <Card className={this.props.cl}>
                <div className="row no-gutters">
                    <div className="col-md-3">
                        <Card.Img variant="top" src={this.props.pic} />
                    </div>
                    <div className="col-md-8">
                        <Card.Body>
                            <Card.Title>{this.props.name}</Card.Title>
                            <hr />
                            <Card.Body className="imgCardInsideBody">
                                {this.props.message}
                                {(this.props.footerItems) ? <hr /> : ""}
                                {this.props.footerItems}
                            </Card.Body>
                        </Card.Body>
                    </div>
                </div>
            </Card>
        );
    }

}

export default ImgCard;