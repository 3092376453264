import React, { Component } from "react";
import SdgByIndJSON from '../JsonData/SDGByIndustry.json';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../Stylesheets/SDGsByIndustry.scss';

const columns = [
    {
        dataField: 'Industry',
        text: 'Supersector',
        sort: false
    }, {
        dataField: 'Sample Size',
        text: 'Sample Size ³',
        sort: false
    }, {
        dataField: "SDG 1",
        text: "SDG 1",
        sort: false,
        searchable: false,
    }, {
        dataField: 'SDG 2',
        text: 'SDG 2',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 3',
        text: 'SDG 3',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 6',
        text: 'SDG 6',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 7',
        text: 'SDG 7',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 8',
        text: 'SDG 8',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 9',
        text: 'SDG 9',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 10',
        text: 'SDG 10',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 11',
        text: 'SDG 11',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 12',
        text: 'SDG 12',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 13',
        text: 'SDG 13',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 14',
        text: 'SDG 14',
        sort: false,
        searchable: false
    }, {
        dataField: 'SDG 15',
        text: 'SDG 15',
        sort: false,
        searchable: false
    },
    {
        dataField: 'Overall envSDG Score',
        text: 'Overall envSDG',
        sort: false
    },
];
const columns2 = [
    {
        dataField: 'Industry',
        text: 'Industry',
        sort: true
    }, {
        dataField: 'Sample Size',
        text: 'Sample Size',
        sort: true
    }, {
        dataField: "SDG 1",
        text: "SDG 1",
        sort: true,
        searchable: false,
    }, {
        dataField: 'SDG 2',
        text: 'SDG 2',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 3',
        text: 'SDG 3',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 6',
        text: 'SDG 6',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 7',
        text: 'SDG 7',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 8',
        text: 'SDG 8',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 9',
        text: 'SDG 9',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 10',
        text: 'SDG 10',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 11',
        text: 'SDG 11',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 12',
        text: 'SDG 12',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 13',
        text: 'SDG 13',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 14',
        text: 'SDG 14',
        sort: true,
        searchable: false
    }, {
        dataField: 'SDG 15',
        text: 'SDG 15',
        sort: true,
        searchable: false
    },
    {
        dataField: 'Overall envSDG Score',
        text: 'Overall envSDG',
        sort: true
    },
];

const expandRow = {
    renderer: row => (
        <BootstrapTable bootstrap4={true} keyField="key" columns={columns2} data={row.children.sort((a, b) => (a["Sample Size"] < b["Sample Size"]) ? 1 : -1)} striped
            condensed />
    ),
    showExpandColumn: true,
    onlyOneExpanding: true,
    nonExpandable: [17],
    className: 'expandedTable'
};

//const { SearchBar, ClearSearchButton } = Search;
// const { ExportCSVButton } = CSVExport;


class SDGsByIndustry extends Component {

    render() {
        return (
            <ToolkitProvider
                keyField="key"
                bootstrap4
                data={SdgByIndJSON}
                columns={columns}
            >
                {
                    props => (
                        <div>
                            <hr />
                            <BootstrapTable {...props.baseProps} striped
                                condensed
                                rowStyle={{ overflowWrap: 'break-word' }}
                                id={"SDGsByIndustryTable"}
                                expandRow={expandRow}
                            />
                            {/* <ExportCSVButton {...props.csvProps} className="btn btn-outline-dark">Export shown data as CSV</ExportCSVButton> */}
                        </div>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export default SDGsByIndustry;