import React, { Component } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import '../Stylesheets/AutocompletionSearch.scss'

export class AutocompletionSearch extends Component {
    componentDidMount() {
    }
    handleOnSearch = (string, cached) => {
        //console.log(string, cached);
    }
    handleOnSelect = item => {
        // the item selected
        //console.log(item);
        this.props.searchCallback(item);
    }
    handleOnFocus = () => {
        //console.log("Focused");
    }
    render() {
        return (<div className="searchHolderDiv">
            <ReactSearchAutocomplete
                items={this.props.data}
                fuseOptions={
                    {
                        keys: [
                            "name",
                            "Cname",
                        ]
                    }
                }
                onSearch={this.handleOnSearch}
                onSelect={this.handleOnSelect}
                onFocus={this.handleOnFocus}
                autoFocus={true}
                maxResults={5}

            />
        </div>
        )
    }
}

export default AutocompletionSearch
