import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import SdgGoal from '../Components/SdgGoal';
import { Sdg1, Sdg2, Sdg3, Sdg4, Sdg5, Sdg6, Sdg7, Sdg8, Sdg9, Sdg10, Sdg11, Sdg12, Sdg13, Sdg14, Sdg15, Sdg16, Sdg17 } from '../Images/SDG';
import ModalPrompt from '../Components/ModalPrompt';
import ExploreMore from '../Components/ExploreMore';
import ProjectHighlights from '../Components/PorjectHighlights';

import '../Stylesheets/Homepage.scss';

const links = ["https://www.un.org/sustainabledevelopment/poverty/", "https://www.un.org/sustainabledevelopment/hunger/", "https://www.un.org/sustainabledevelopment/health/", "https://www.un.org/sustainabledevelopment/education/", "https://www.un.org/sustainabledevelopment/gender-equality/", "https://www.un.org/sustainabledevelopment/water-and-sanitation/", "https://www.un.org/sustainabledevelopment/energy/", "https://www.un.org/sustainabledevelopment/economic-growth/", "https://www.un.org/sustainabledevelopment/infrastructure-industrialization/", "https://www.un.org/sustainabledevelopment/inequality/", "https://www.un.org/sustainabledevelopment/cities/", "https://www.un.org/sustainabledevelopment/sustainable-consumption-production/", "https://www.un.org/sustainabledevelopment/climate-change/", "https://www.un.org/sustainabledevelopment/oceans/", "https://www.un.org/sustainabledevelopment/biodiversity/", "https://www.un.org/sustainabledevelopment/peace-justice/", "https://www.un.org/sustainabledevelopment/globalpartnerships/"];

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      url: '',
      mssg: "",
    };
  }

  clickedRow = (e) => {
    var _mssg = "Learn more about \"" + e.target.innerText.trim() + "\" over at the UN site?";
    this.setState({ show: true, url: links[e.target.id.substring(1) - 1], mssg: _mssg });
  }
  closePrompt = () => {
    this.setState({ show: false });
  }
  navigate = () => {
    this.closePrompt();
    window.location.href = this.state.url;
  }
  render() {
    return (
      <div>
        <div className="column">
          <div className="row"><div className="column">
            <h3>Corporate Sustainable Development Disclosure Monitor</h3><hr />
            <p>The United Nations Sustainable Development Goals (SDGs) are a set of objectives developed as “a universal call to action to end poverty, protect the planet, and ensure that all people enjoy peace and prosperity by 2030.” In total there are 17 SDGs and within each Goal is a set of targets, which are more specific criteria to be worked toward in order to accomplish the overarching Goals.</p>
            <p>CSD Disclosure Monitor measures a company’s commitment in terms of its quality of disclosure toward the SDGs by mapping the disclosure scores of the Weissman Center’s CSR-S Monitor® (<a href="https://www.csrsmonitor.org" target="_blank" rel="noreferrer">www.csrsmonitor.org</a>) to the various targets within the SDGs.</p>
          </div></div>
          <div className="row"><div className="homepageHolder column"><br />
            <h3>Sustainable Development Goals</h3>
            <hr /><br />

            <Table responsive>
              <thead>
                <tr>
                  <th>Goal 1-6</th>
                  <th>Goal 7-12</th>
                  <th>Goal 13-17</th>
                </tr>
              </thead>
              <tbody className="homepageSDGtbody">
                <tr>

                  <td onClick={this.clickedRow} id="s1"> <SdgGoal cl="hmTable" src={Sdg1} /> Goal 1: No Poverty</td>
                  <td onClick={this.clickedRow} id="s7"><SdgGoal cl="hmTable" src={Sdg7} /> Goal 7: Affordable & Clean Energy</td>
                  <td onClick={this.clickedRow} id="s13"><SdgGoal cl="hmTable" src={Sdg13} /> Goal 13: Climate Action</td>
                </tr>
                <tr>
                  <td onClick={this.clickedRow} id="s2"><SdgGoal cl="hmTable" src={Sdg2} /> Goal 2: Zero Hunger</td>
                  <td onClick={this.clickedRow} id="s8"><SdgGoal cl="hmTable" src={Sdg8} /> Goal 8: Decent Work & Economic Growth</td>
                  <td onClick={this.clickedRow} id="s14"><SdgGoal cl="hmTable" src={Sdg14} /> Goal 14: Life Below Water</td>
                </tr><tr>
                  <td onClick={this.clickedRow} id="s3"><SdgGoal cl="hmTable" src={Sdg3} /> Goal 3: Good Health & Well Being</td>
                  <td onClick={this.clickedRow} id="s9"><SdgGoal cl="hmTable" src={Sdg9} /> Goal 9: Industry, Innovation, & Infrastructure</td>
                  <td onClick={this.clickedRow} id="s15"><SdgGoal cl="hmTable" src={Sdg15} /> Goal 15: Life on Land</td>
                </tr>
                <tr>
                  <td onClick={this.clickedRow} id="s4"><SdgGoal cl="hmTable" src={Sdg4} /> Goal 4: Quality Education</td>
                  <td onClick={this.clickedRow} id="s10"><SdgGoal cl="hmTable" src={Sdg10} /> Goal 10: Reduced Inequalities</td>
                  <td onClick={this.clickedRow} id="s16"><SdgGoal cl="hmTable" src={Sdg16} /> Goal 16: Peace, Justice, & Strong Institutions</td>
                </tr>
                <tr>
                  <td onClick={this.clickedRow} id="s5"><SdgGoal cl="hmTable" src={Sdg5} /> Goal 5: Gender Equality</td>
                  <td onClick={this.clickedRow} id="s11"><SdgGoal cl="hmTable" src={Sdg11} /> Goal 11: Sustainable Cities & Communities</td>
                  <td onClick={this.clickedRow} id="s17"><SdgGoal cl="hmTable" src={Sdg17} /> Goal 17: Partnerships for the Goals</td>
                </tr>
                <tr>
                  <td onClick={this.clickedRow} id="s6"><SdgGoal cl="hmTable" src={Sdg6} /> Goal 6: Clean Water & Sanitation</td>
                  <td onClick={this.clickedRow} id="s12"><SdgGoal cl="hmTable" src={Sdg12} /> Goal 12: Responsible Consumption & Production</td>
                </tr>
              </tbody>
            </Table>
          </div></div>
        </div><br /><br />
        <ExploreMore />
        <br /><br />
        <ProjectHighlights />
        <ModalPrompt show={this.state.show} message={this.state.mssg} close={() => this.closePrompt()} action={() => this.navigate()} />
      </div >
    )
  }
}

export default HomePage;