import React, {Component} from "react";
import Image from 'react-bootstrap/Image'



class SdgGoal extends Component {
    render() {
        return(
            <Image className={this.props.cl} src={this.props.src} rounded />
        );
    }
    
}

export default SdgGoal;