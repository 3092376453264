import React, { Component } from "react";
import '../Stylesheets/FlipCard.scss';



class FlipCard extends Component {
    render() {
        return (
            < div className="flip-card" >
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={this.props.src} alt="SDG Goal card" className="flip-card-img" />
                    </div>
                    <div className="flip-card-back" style={{ backgroundColor: this.props.backColor }}>
                        <h1>{this.props.title}</h1>
                        <p>{this.props.firstLine}</p>
                        {this.props.isLink ? <a href={this.props.link} target="_blank" rel="noreferrer">{this.props.linktext}</a> : <p>{this.props.secondLine}</p>}
                    </div>
                </div>
            </div >
        );
    }

}

export default FlipCard;