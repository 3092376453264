import './Stylesheets/App.scss';
import { Route, Switch, useParams } from "react-router-dom";
import { HomePage, AboutPage, TeamPage, AnalyticsPage, ReportsPage, SDGPage, FlowOMaticPage, SdgsbyIndustryPage, D3BarChartPage, RegionSDGTablePage, CompanyProfilePage, SearchPage, Team_archive } from './Pages';

function Company() {
  let { id } = useParams();
  return <CompanyProfilePage cid={id} />;
}

export default function App() {
  return (
    <section className="appContent">
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/archives/team" component={Team_archive} />
        <Route path="/analytics" component={AnalyticsPage} />
        <Route exact path="/Rankings" component={SDGPage} />
        <Route path="/sdg/envSDG-Methodology" component={FlowOMaticPage} />
        <Route path="/sdg/envSDG-Industry" component={SdgsbyIndustryPage} />
        <Route path="/sdg/envSDG-Statistics" component={D3BarChartPage} />
        <Route path="/sdg/envSDG-Region" component={RegionSDGTablePage} />
        <Route path="/team" component={TeamPage} />
        <Route path="/reports" component={ReportsPage} />
        <Route path="/Company/:id" component={Company} />
        <Route exact path="/results" component={SearchPage} />
        <Route path="/:id">
          <h1>The page you requested does not exist.</h1>
        </Route>
      </Switch>
    </section>
  );
}