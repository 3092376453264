import React, { Component } from "react";
import '../Stylesheets/AsterChart.scss'
import * as d3 from "d3";
import { select } from 'd3-selection';
// eslint-disable-next-line
import transition from 'd3-transition';

import DataCSV from '../CsvData/AsterChart.csv'; //temp data for aster chart

class AsterChart extends Component {

    componentDidMount() {
        this.DrawChart();
    }

    DrawChart = (data) => {

        var width = this.props.width,
            height = this.props.height,
            radius = Math.min(width, height) / 2,
            innerRadius = 0.3 * radius;

        var pie = d3.pie()
            .sort(null)
            .value(function (d) { return d.width; });
        var arc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(function (d) {
                return (radius - innerRadius) * (d.data.Score / d.data.MaxVal) + innerRadius;
            });

        var outlineArc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(radius);

        var _sdgs = this.props.sdgs;
        var sdgKeys = Object.keys(_sdgs);
        var dataset = d3.csv(DataCSV);
        dataset.then(function (data) {
            data.map(function (d, i) {
                //d.Goal = d.Goal;
                d.MaxVal = +d.MaxVal;
                d.Score = _sdgs[sdgKeys[i]] * d.MaxVal; //TODO confirm this is the calculation
                d._Score = _sdgs[sdgKeys[i]] * 100;
                d.Weight = +d.Weight;
                d.width = +d.Weight;
                //d.Overall = d.Overall;
                //d.Color = d.Color;
                return d;
            });
        });

        dataset.then((data) => {
            d3.select("#AsterChart").append("div")
                .attr("class", "aster_tooltip")
                .style("opacity", 0);
            var svg = d3.select("#AsterChart").append("svg")
                .attr("width", width + 140)
                .attr("height", height)
                .append("g")
                .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
            svg.selectAll(".solidArc")
                .data(pie(data))
                .enter().append("path")
                .attr("fill", function (d) { return d.data.Color; })
                .attr("class", "solidArc")
                .attr("stroke", "gray")
                .attr("d", arc)
                .on("mousemove", (i, d) => {
                    select('.aster_tooltip').transition()
                        .duration(50)
                        .style("opacity", 1);
                    return select('.aster_tooltip').html("<p>" + d.data.Goal + "</p> <p><span class='aster_ttip_title'>Company Score:</span><span class='aster_ttip_value'>" + (d.data._Score).toFixed(2) + "%</span></p><hr style='margin:0;'/><p><span class='aster_ttip_title'>Intensity factor:</span><span class='aster_ttip_value'>" + (d.data.Weight * 100).toFixed(2) + "%</span></p>")
                        .style("left", (i.pageX + 10) + "px")
                        .style("top", (i.pageY + 10) + "px");

                })
                .on("mouseout", () => {
                    select('.aster_tooltip').transition()
                        .duration(50)
                        .style("opacity", 0);
                })
            // .on('mouseover', (i, d) => { tip.show(d) })
            // .on('mouseout', tip.hide);

            svg.selectAll(".outlineArc")
                .data(pie(data))
                .enter().append("path")
                .attr("fill", "none")
                .attr("stroke", "#dee2e6")
                .attr("class", "outlineArc")
                .attr("d", outlineArc);


            // calculate the weighted mean score
            svg.append("svg:text")
                .attr("class", "aster-score")
                .attr("dy", ".35em")
                .attr("text-anchor", "middle") // text-align: right
                .text((_sdgs["Overall SDG Score"] * 100).toFixed(2) + "%");


            svg.append("text")
                .attr("x", 220)
                .attr("y", -230)
                .text("height:")
                .attr("class", "aster_legend")
                .attr("alignment-baseline", "top");
            svg.append("text")
                .attr("x", 275)
                .attr("y", -230)
                .text("score")
                .attr("class", "aster_legend")
                .attr("alignment-baseline", "top");
            svg.append("text")
                .attr("x", 220)
                .attr("y", -210)
                .text("width:")
                .attr("class", "aster_legend")
                .attr("alignment-baseline", "top");
            svg.append("text")
                .attr("x", 275)
                .attr("y", -210)
                .text("intensity factor")
                .attr("class", "aster_legend")
                .attr("alignment-baseline", "top");


        });
    }
    render() {
        return (
            <div id="AsterChart" >
            </div>
        );
    }
}

export default AsterChart;


//plausable example here: http://bl.ocks.org/bbest/2de0e25d4840c68f2db1

// example for cluster bar / grouped bars: https://observablehq.com/@d3/grouped-bar-chart
