import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import MethodologyIMG from "../Images/stockImages/Methodology.png";
import RankingsIMG from "../Images/stockImages/Rankings.png";
import Analytics from "../Images/stockImages/Analytics.png";
import CsrLogo from "../Images/Csr_Logo.png";
import PdfIMG from "../Images/Pdf.png";

import "../Stylesheets/ExploreMore.scss";

import Pdf from '../Documents/Booklet.pdf';

class ExploreMore extends Component {
    state = {
        weatherData: ''
    };
    render() {
        return (
            <section className="exploreContainer">
                <h3>Explore the Project</h3>
                <hr />
                <Card className="exploreCard">
                    <div className="row no-gutters">
                        <div className="col-md-4">
                            <Card.Img variant="top" src={PdfIMG} />
                        </div>
                        <div className="col-md-8">
                            <Card.Body>
                                <Card.Title>CSD-Disclosure Summary Report</Card.Title>
                                <Card.Text>
                                An introduction to the project and example analysis of results
                        </Card.Text>
                                <Button variant="primary" href={Pdf} target="_blank" rel="noreferrer">Download PDF</Button>
                            </Card.Body>
                        </div>
                    </div>
                </Card>
                <Card className="exploreCard">
                    <div className="row no-gutters">
                        <div className="col-md-4">
                            <Card.Img variant="top" src={MethodologyIMG} />
                        </div>
                        <div className="col-md-8">
                            <Card.Body>
                                <Card.Title>Methodology</Card.Title>
                                <Card.Text>
                                    Explore the details of mapping the CSR-S Monitor® to the SDGs
                        </Card.Text>
                                <Button variant="primary" href="/sdg/envSDG-Methodology">Explore more</Button>
                            </Card.Body>
                        </div>
                    </div>
                </Card>
                <Card className="exploreCard">
                    <div className="row no-gutters">
                        <div className="col-md-4">
                            <Card.Img variant="top" src={RankingsIMG} />
                        </div>
                        <div className="col-md-8">
                            <Card.Body>
                                <Card.Title>Rankings</Card.Title>
                                <Card.Text>
                                    Explore the environmentally-related SDG (envSDG) scores of Fortune Global 500 companies
                        </Card.Text>
                                <Button variant="primary" href="/Rankings">Explore more</Button>
                            </Card.Body>
                        </div>
                    </div>
                </Card>
                <Card className="exploreCard">
                    <div className="row no-gutters">
                        <div className="col-md-4">
                            <Card.Img variant="top" src={Analytics} />
                        </div>
                        <div className="col-md-8">
                            <Card.Body>
                                <Card.Title>Analytics</Card.Title>
                                <Card.Text>
                                    Explore highlights from our analysis of envSDG scores
                        </Card.Text>
                                <Button variant="primary" href="/sdg/envSDG-Statistics">Explore more</Button>
                            </Card.Body>
                        </div>
                    </div>
                </Card>
                <Card className="exploreCard">
                    <div className="row no-gutters">
                        <div className="col-md-4">
                            <Card.Img variant="top" src={CsrLogo} />
                        </div>
                        <div className="col-md-8">
                            <Card.Body>
                                <Card.Title>CSR-S Monitor</Card.Title>
                                <Card.Text>
                                    Discover the details of the disclosure scores sourced from the CSR-S Monitor® project
                        </Card.Text>
                                <Button variant="primary" href="https://www.csrsmonitor.org" target="_blank" rel="noreferrer">Explore more</Button>
                            </Card.Body>
                        </div>
                    </div>
                </Card>
                <br />
            </section>
        );
    }
}
export default ExploreMore;