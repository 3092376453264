import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { Link, withRouter } from 'react-router-dom';

import logo from '../Images/logo.png';

class navbar extends Component {

    state = {
        searchText: ""
    };

    handleRoute = route => () => {
        this.props.history.push({ pathname: route });
    };

    handleSearchInput = event => {
        this.setState({
            searchText: event.target.value
        });
    };
    handleSearchSubmit = () => {
        if (this.state.searchText) {
            let text = this.state.searchText;
            this.setState({ searchText: "" })
            this.props.history.push({
                pathname: "/results",
                state: { searchText: text.toLowerCase() }
            });
        } else {
            alert("Please enter some search text!");
        }
    };
    handleSearchKeyUp = event => {
        event.preventDefault();
        if (event.key === 'Enter' && event.keyCode === 13) {
            this.handleSearchSubmit();
        }
    }

    handleFormSubmit = e => e.preventDefault();

    render() {
        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand as={Link} to="/"><img id="csr_logo" className="img-responsive" src={logo} alt="Home" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="mainNavi">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                        <Nav.Link as={Link} to="/sdg/envSDG-Methodology">Methodology</Nav.Link>
                        <Nav.Link as={Link} to="/Rankings">Rankings</Nav.Link>
                        <NavDropdown title="Analytics" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/sdg/envSDG-Statistics">envSDG Statistics</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/sdg/envSDG-Industry">envSDGs by Industry</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/sdg/envSDG-Region">envSDG by Region</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/team">Our Team</Nav.Link>
                    </Nav>
                    <Form inline onSubmit={this.handleFormSubmit}>
                        <FormControl type="text" placeholder="Search" className="mr-sm-2" onChange={this.handleSearchInput}
                            value={this.state.searchText}
                            onKeyUp={this.handleSearchKeyUp} />

                        <Button onClick={this.handleSearchSubmit} variant="outline-success">Search</Button>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}
export default withRouter(navbar);
