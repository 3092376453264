
import React, { Component } from "react";
import '../../Stylesheets/TeamPage.scss';

class TeamPage extends Component {
    state = {
        openId: 0
    };

    selectOpen = openId => {
        this.setState({ openId });
    };
    render() {
        return (
            <div className="col-sm">
                <h4>Some older report:</h4>
                <ul className="list-group"><li className="list-group-item">Laura Agosto</li>
                    <li className="list-group-item">Anna Chan</li>
                    <li className="list-group-item">Qijun Chen</li>
                    <li className="list-group-item">Alexander Fermin</li>
                    <li className="list-group-item">Amira Fleyshmakher</li>
                    <li className="list-group-item">Chandni Gupta</li>
                    <li className="list-group-item">Mayank Kapoor</li>
                    <li className="list-group-item">Barbara Kontic</li>
                    <li className="list-group-item">Yelena Melnichenko</li>
                    <li className="list-group-item">Elizabeth Rotenberg</li>
                </ul><h4>&nbsp;</h4>
            </div>
        )
    }
}

export default TeamPage;