
import React, { Component } from "react";
import RegionSDGTable from '../Components/RegionSDGTable';

import SDGTiles from '../Components/SDGTiles';


class RegionSDGTablePage extends Component {
    render() {
        return (
            <div>
                <h3>Region-based Findings</h3>
                <hr />
                <div>
                    <ul>
                        <li>
                            299 of 324 companies in our sample are headquartered in East Asia, North America, or Western Europe.
                        </li>
                        <li>
                            Of those three “large sample size regions,” Western Europe has the highest overall average with 35.50 percent, followed by North America with 29.90 percent and East Asia with 29.02 percent. The average overall scores across the large sample size regions are surprisingly low.
                        </li>
                        <li>
                            SDG #10 (Reduced Inequalities), SDG #7 (Affordable and Clean Energy), and SDG #13 (Climate Action) are the three highest-scoring SDGs across the different regions, consistent with our overall findings.
                        </li>
                        <li>
                            See the Executive Summary report (hyperlink: TBD) for more findings and discussions.
                        </li>
                    </ul>
                </div>

                <br />
                <RegionSDGTable />
                <br /><br />
                <div className="chart_footnote">Footnote 1: Note that SDGs 4, 5, 16, and 17 are excluded as they do not have environmentally-related targets that we could score.</div>
                <div className="chart_footnote">Footnote 2: “Others" consists of the following regions (and sample sizes)
                <ul>
                        <li>Oceania (8)</li>
                        <li>Latin America and the Caribbean (7)</li>
                        <li>Eastern Europe and Central Asia (5)</li>
                        <li>South Asia (4)</li>
                        <li>Middle East and North Africa (1)</li>
                    </ul>
                </div>
                <hr />
                <SDGTiles />
                <hr />

            </div>
        )
    }
}

export default RegionSDGTablePage;