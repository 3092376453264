import React, { Component } from "react";
import * as d3 from "d3";
import DataJSON from '../JsonData/ClusterBarChart.json';

import '../Stylesheets/ClusterBar.scss';

class ClusterBarChart extends Component {

    constructor(props) {
        super(props);
        // eslint-disable-next-line
        var FormatArr = {};
        // eslint-disable-next-line
        var DataArr = {};
        // eslint-disable-next-line
        var CompanyName = "";
        // eslint-disable-next-line
        var chartKeys = [];
        this._generateCompanyData();
        this._generateAveragesData(DataJSON);
    }

    _generateCompanyData = () => {
        // we take company data and merge it with dataJSON and populate new dataset
        this.FormatArr = { industry: this.props.ind, region: this.props.reg, aver: this.props.sdgs };
        this.CompanyName = this.props.cname;
    }
    _generateAveragesData = (averData) => {
        //lets get # of sdg goals in json
        this.chartKeys = Object.keys(averData.industry[this.FormatArr.industry]);
        this.DataArr = this.chartKeys.map((key) => {
            return (
                { "name": key, [this.CompanyName]: this.FormatArr.aver[key], [this.FormatArr.industry]: averData.industry[this.FormatArr.industry][key], [this.FormatArr.region]: averData.region[this.FormatArr.region][key], "Overall": averData.overall[key] }
            );
        });
    }
    componentDidMount() {
        this.drawChart();
    }
    drawChart = () => {
        //svg +200px (150 of those are for x-axis text space)

        var margin = { top: 20, right: 20, bottom: 30, left: 40 },
            width = this.props.width - margin.left - margin.right,
            height = this.props.height - margin.top - margin.bottom,
            svg = d3.select("#ClusterBarChart").append("svg")
                .attr("width", width + 50)
                .attr("height", height + 100),
            g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // The scale spacing the groups:
        var x0 = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.1);

        // The scale for spacing each group's bar:
        var x1 = d3.scaleBand()
            .padding(0.05);

        var y = d3.scaleLinear()
            .rangeRound([height, 0]);

        var z = d3.scaleOrdinal()
            .range(["#749a4b", "#478b6a", "#247f82", "#00739a"]); //colors for bars in 1 group

        var data = this.DataArr;
        var _arrtemp = data[0];
        var keys = Object.keys(_arrtemp);
        keys = keys.slice(1);
        //
        var _res = "";
        x0.domain(data.map(function (d) {
            _res = d.name;
            if (d.name.split(" ")[0] !== "Overall") _res = "SDG " + d.name.split(" ")[0];
            return d.name = _res;

        }));
        x1.domain(keys).rangeRound([0, x0.bandwidth()]);
        y.domain([0, 1]).nice();
        g.append("g")
            .attr("class", "grid")
            .call(d3.axisLeft(y)
                .tickSize(-width)
                .tickFormat("")
            );
        g.append("g")
            .selectAll("g")
            .data(data)
            .enter().append("g")
            .attr("class", "bar")
            .attr("transform", function (d) { return "translate(" + x0(d.name) + ",0)"; })
            .selectAll("rect")
            .data(function (d) { return keys.map(function (key) { return { key: key, value: d[key] }; }); })

            .enter().append("rect")
            .attr("x", function (d) { return x1(d.key); })
            .attr("y", function (d) { return y(d.value); })
            .attr("width", x1.bandwidth())
            .attr("height", function (d) { return height - y(d.value); })
            .attr("fill", function (d) { return z(d.key); });

        g.append("g")
            .attr("class", "axis")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x0))
            .selectAll("text")
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-35)");

        g.append("g")
            .attr("class", "y axis")
            .call(d3.axisLeft(y).tickFormat(d => d * 100 + "%"))
            .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("dy", ".32em")
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start")
            .text("Score");

        var legend = g.append("g")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .attr("text-anchor", "end")
            .selectAll("g")
            .data(keys.slice())
            .enter().append("g")
            .attr("transform", function (d, i) { return "translate(0," + i * 20 + ")"; });

        legend.append("rect")
            .attr("x", width - 17)
            .attr("width", 15)
            .attr("height", 15)
            .attr("fill", z)
            .attr("stroke", z)
            .attr("stroke-width", 2)
            .on("click", function (d) { update(d) });

        legend.append("text")
            .attr("x", width - 24)
            .attr("y", 9.5)
            .attr("dy", "0.32em")
            .text(function (d) { return d; });

        var filtered = [];

        function update(d) {

            //
            // Update the array to filter the chart by:
            //

            // add the clicked key if not included:
            if (filtered.indexOf(d) === -1) {
                filtered.push(d);
                // if all bars are un-checked, reset:
                if (filtered.length === keys.length) filtered = [];
            }
            // otherwise remove it:
            else {
                filtered.splice(filtered.indexOf(d), 1);
            }

            //
            // Update the scales for each group(/states)'s items:
            //
            var newKeys = [];
            keys.forEach(function (d) {
                if (filtered.indexOf(d) === -1) {
                    newKeys.push(d);
                }
            })
            x1.domain(newKeys).rangeRound([0, x0.bandwidth()]);
            y.domain([0, d3.max(data, function (d) { return d3.max(keys, function (key) { if (filtered.indexOf(key) === -1) return d[key]; }); })]).nice();

            // update the y axis:
            svg.select(".y")
                .transition()
                .call(d3.axisLeft(y).ticks(null, "s"))
                .duration(500);


            //
            // Filter out the bands that need to be hidden:
            //
            var bars = svg.selectAll(".bar").selectAll("rect")
                .data(function (d) { return keys.map(function (key) { return { key: key, value: d[key] }; }); })

            bars.filter(function (d) {
                return filtered.indexOf(d.key) > -1;
            })
                .transition()
                .attr("x", function (d) {
                    return (+d3.select(this).attr("x")) + (+d3.select(this).attr("width")) / 2;
                })
                .attr("height", 0)
                .attr("width", 0)
                .attr("y", function (d) { return height; })
                .duration(500);

            //
            // Adjust the remaining bars:
            //
            bars.filter(function (d) {
                return filtered.indexOf(d.key) === -1;
            })
                .transition()
                .attr("x", function (d) { return x1(d.key); })
                .attr("y", function (d) { return y(d.value); })
                .attr("height", function (d) { return height - y(d.value); })
                .attr("width", x1.bandwidth())
                .attr("fill", function (d) { return z(d.key); })
                .duration(500);


            // update legend:
            legend.selectAll("rect")
                .transition()
                .attr("fill", function (d) {
                    if (filtered.length) {
                        if (filtered.indexOf(d) === -1) {
                            return z(d);
                        }
                        else {
                            return "white";
                        }
                    }
                    else {
                        return z(d);
                    }
                })
                .duration(100);


        }
    }

    render() {
        return (
            <div><h3>Comparison Chart | {this.CompanyName}</h3><hr /><div id="ClusterBarChart"></div></div>
        );
    }
}

export default ClusterBarChart;