import React, { Component } from "react";
import SDGTiles from '../Components/SDGTiles';
import CompaniesBySDG from '../Components/CompaniesBySDG';

import '../Stylesheets/SDGPage.scss';


class CompaniesBySDGPage extends Component {

    render() {
        return (
            <div className="sdgMainContainer">
                <h3>Companies by SDG</h3>
                <SDGTiles />
                <CompaniesBySDG history={this.props.history} />
                <br /><br /><br /><br /><br /><br />
            </div>
        )
    }
}

export default CompaniesBySDGPage;