import React, { Component } from "react";
import CompaniesJSON from '../JsonData/CompanyList.json';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import '../Stylesheets/SearchPage.scss';

const columns = [{
    dataField: 'CID',
    text: 'cid',
    hidden: true,
}, {
    dataField: 'Cname',
    text: 'Company',
    classes: "searchColumns",
}, {
    dataField: 'Industry',
    text: 'Industry',
    classes: "searchColumns",
}, {
    dataField: 'Region',
    text: 'Region',
    classes: "searchColumns",
}, {
    dataField: 'Country',
    text: 'Country',
    classes: "searchColumns",
},
];
const pagination = paginationFactory({
});
class SearchPage extends Component {
    state = {
        isLoading: true,
        searchText: "",
        searchResults: [],
        show: false,
        cname: "",
        cid: 0,
    };
    _clicked(row) {
        this.setState({ show: true, cname: row.Cname, cid: row.CID });
    }
    handleClose = () => {
        this.setState({ show: false });
    }
    navigate = () => {
        this.props.history.push('/Company/' + this.state.cid);
    }

    handleSearch = () => {
        let searchText = this.props.location.state.searchText;
        let results = CompaniesJSON.filter(item => item.Cname.toLowerCase().includes(searchText));
        this.setState({
            isLoading: false,
            searchText: searchText,
            searchResults: results
        });
    };

    componentDidMount() {
        this.handleSearch();
    }

    componentDidUpdate(prevProps) {
        let prevSearch = prevProps.location.state.searchText;
        let newSearch = this.props.location.state.searchText;
        if (prevSearch !== newSearch) {
            this.handleSearch();
        }
    }

    render() {
        let toRender = this.state.isLoading ? (
            <h1>Loading...</h1>
        ) : (
                <>
                    <h1>Your Search Results</h1><hr />
                    <h4>Search string: "{this.state.searchText}"</h4>
                    <h4>Found results: {this.state.searchResults.length}</h4>
                    <hr />
                    {this.state.searchResults.length > 0 ? (
                        <pre>
                            <BootstrapTable keyField='CID' data={this.state.searchResults} classes="no-gutters" columns={columns} pagination={pagination} hover={true} rowEvents={{
                                onClick: (e, row, rowIndex) => {
                                    this._clicked(row);
                                }
                            }}
                            />
                        </pre>
                    ) : (
                            <p>NO RESULTS FOUND</p>
                        )}
                    <Modal show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Leaving page</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Would you like to see {this.state.cname} company page?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Close
            </Button>
                            <Button variant="primary" onClick={this.navigate}>
                                Proceed
            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            );

        return <div>{toRender}</div>;
    }
}

export default SearchPage;