import React, { Component } from "react";
import Navig from './Navbar';
import '../Stylesheets/Header.scss';

import Pdf from '../Documents/Booklet.pdf';

class Header extends Component {

    componentDidMount() {
        var _h = this.calcHeaderHeight("header");
        var _lh =this.calcHeaderHeight("csr_logo");
        this.drawHeader(_h,_lh);
    }
    calcHeaderHeight = (c) => {
        return document.getElementById(c).clientHeight;
    }
    drawHeader = (h,lh) => {
        window.onscroll = function () { scrollFunction() };

        function scrollFunction() {
            if (document.body.scrollTop > 70 || document.documentElement.scrollTop > 70) {
                document.getElementById("header").style.height = h / 2 + "px";
                document.getElementById("csr_logo").style.height = lh / 3 + "px";
            } else {
                document.getElementById("header").style.height = "auto";
                document.getElementById("csr_logo").style.height = lh + "px";
            }
        }
    }

    render() {
        return (
            <div id="header" className="header stickyHeader">
                <Navig />
                <div className="reportHeadlinerWrapper"><div className="reportHeadliner">CSD-Disclosure Summary Report:  <a href = {Pdf} target = "_blank" rel="noreferrer">Download PDF</a></div></div>
            </div>
        )
    }
}

export default Header;