import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
//import SdgGoal from './SdgGoal';
import { Sdg1, Sdg2, Sdg3, Sdg6, Sdg7, Sdg8, Sdg9, Sdg10, Sdg11, Sdg12, Sdg13, Sdg14, Sdg15 } from '../Images/SDG';
import '../Stylesheets/RegionSDGTable.scss';

const goals = [Sdg1, Sdg2, Sdg3, "4", "5", Sdg6, Sdg7, Sdg8, Sdg9, Sdg10, Sdg11, Sdg12, Sdg13, Sdg14, Sdg15];

class RegionSDGTable extends Component {
    render() {
        return (
            <div className="column">
                <br />
                <p className="row no-gutters">The following table shows the average envSDG Score for each individual SDG by Region. ¹</p>
                <div className="row no-gutters">
                    <div className="column sdgRegTable no-gutters">
                        <Table responsive="sm" className="table-striped tableByRegion">
                            <thead>
                                <tr>
                                    <th>Region</th>
                                    <th>Sample Size</th>
                                    {goals.flatMap((sdg, key) => {
                                        //console.log(key);
                                        if (key === 3 || key === 4) { return false; };
                                        //<th key={key}><SdgGoal cl="sdgRegTableIcon" src={sdg} key={key} /></th>
                                        return (<th key={key}>SDG {key + 1}</th>);
                                    })}
                                    {/* <th><div className="regionSDGTable_Overall"><span>Overall</span><span>envSDG</span><span> Score</span></div></th> */}
                                    <th><div className=""><span>Overall </span><span>envSDG</span></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>East Asia</td>
                                    <td>94</td>
                                    <td>31.50%</td>
                                    <td>28.57%</td>
                                    <td>29.71%</td>
                                    <td>24.74%</td>
                                    <td>41.06%</td>
                                    <td>21.25%</td>
                                    <td>22.61%</td>
                                    <td>71.28%</td>
                                    <td>27.69%</td>
                                    <td>28.31%</td>
                                    <td>40.63%</td>
                                    <td>26.97%</td>
                                    <td>23.74%</td>
                                    <td>29.02%</td>
                                </tr>
                                <tr>
                                    <td>North America</td>
                                    <td>94</td>
                                    <td>29.14%</td>
                                    <td>24.66%</td>
                                    <td>33.27%</td>
                                    <td>31.00%</td>
                                    <td>44.51%</td>
                                    <td>27.51%</td>
                                    <td>29.17%</td>
                                    <td>71.28%</td>
                                    <td>26.64%</td>
                                    <td>32.87%</td>
                                    <td>39.70%</td>
                                    <td>25.69%</td>
                                    <td>18.78%</td>
                                    <td>29.90%</td>
                                </tr>
                                <tr>
                                    <td>Western Europe</td>
                                    <td>111</td>
                                    <td>36.98%</td>
                                    <td>31.61%</td>
                                    <td>38.33%</td>
                                    <td>33.11%</td>
                                    <td>52.94%</td>
                                    <td>33.52%</td>
                                    <td>34.44%</td>
                                    <td>80.63%</td>
                                    <td>31.96%</td>
                                    <td>38.59%</td>
                                    <td>50.91%</td>
                                    <td>30.12%</td>
                                    <td>23.62%</td>
                                    <td>35.50%</td>
                                </tr>
                                <tr>
                                    <td>Others ²</td>
                                    <td>25</td>
                                    <td>30.96%</td>
                                    <td>28.35%</td>
                                    <td>33.36%</td>
                                    <td>32.15%</td>
                                    <td>35.68%</td>
                                    <td>23.36%</td>
                                    <td>23.83%</td>
                                    <td>74.00%</td>
                                    <td>28.56%</td>
                                    <td>29.73%</td>
                                    <td>40.68%</td>
                                    <td>29.37%</td>
                                    <td>25.54%</td>
                                    <td>30.68%</td>
                                </tr>
                            </tbody>
                        </Table></div>
                </div>
            </div>
        );
    }

}

export default RegionSDGTable;