import React, { Component } from "react";
import '../Stylesheets/FlowOMatic.scss';
import { line } from "d3";
import { select } from "d3-selection"
// eslint-disable-next-line
import { sankey as d3sankey, sankeyLinkHorizontal as d3sankeyLinkHorizontal, sankeyLeft as d3SankeyLeft, sankeyRight as d3SankeyRight, sankeyCenter as d3SankeyCenter, sankeyJustify as d3SankeyJustify } from "d3-sankey";

import sankeydata from '../JsonData/FlowOMaticChart.json'; //temp data for flowomatic chart

class FlowOMatic extends Component {
    constructor(props) {
        super(props);
        this.leftTop = 0;
        this.centerTop = 0;
        this.rightTop = 0;
    }

    componentDidMount() {
        this.DrawChart();
    }

    DrawChart = () => {
        // set the dimensions and margins of the graph
        //var ratio = 1200 / 650;
        //var ratio2 = 1024 / 768;
        var margin = { top: 35, right: 50, bottom: 10, left: 50 },
            width = (window.innerWidth);
        //height = (width / ratio2);

        // append the svg object to the body of the page
        var svg = select("#FlowOMaticChart").append("svg")
            .attr("viewBox", "0 0 1200 650")
            .append("g")
            .attr("height", "auto")
            .attr("width", "100%")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // Set the sankey diagram properties
        //TODO play with values in sort... and align
        var sankey = d3sankey()
            .nodeSort(null)
            .nodeId(d => d.node)
            .nodeAlign(d3SankeyJustify)
            .nodeWidth(7)
            .nodePadding(5)
            .extent([[210, 10], [1024 - 200, 600]]);
        // eslint-disable-next-line
        var path = sankey.links();



        //d3.json("../sankey.json").then(function (sankeydata) {
        var graph = sankey(sankeydata);
        // add in the links
        var link = svg.append("g").selectAll("link")
            .data(graph.links)
            .enter().append("path")
            .attr("class", "link")
            .attr("d", d3sankeyLinkHorizontal())
            .attr("fill", "none")
            .attr("stroke", function (d) { return d.color; })
            .attr("stroke-width", function (d) { return 3; });


        // add the link titles
        link.append("title")
            .text(function (d) {
                return d.source.name + " → " +
                    d.target.name;
            });

        // add in the nodes
        var node = svg.append("g").selectAll(".node")
            .data(graph.nodes)
            .enter().append("g")
            .attr("class", "node");

        // add the rectangles for the nodes
        node.append("rect")
            .attr("x", function (d) { return d.x0; })
            .attr("y", function (d) { return d.y0; })
            .attr("height", function (d) { return d.y1 - d.y0; })
            .attr("width", sankey.nodeWidth())
            .style("fill", function (d) {
                //return d.color = d.color;
                return "steelblue";                     //color change is here
            })
            .attr("stroke", "steelblue")
            .attr("stroke-width", function (d) { return 1; })
            .append("title")
            .text(function (d) {
                return d.name;
            });

        // add in the title for the nodes
        node.append("text")
            .attr("x", function (d) { return d.x0 + 20; })
            .attr("y", function (d) { return (d.y1 + d.y0) / 2; })
            .attr("dy", "0.35em")
            .attr("class", "flomaticText")
            .attr("text-anchor", "start")
            .text(function (d) { return d.name; })
            .filter(function (d) { return d.x0 < (1200 / 2); })
            .attr("x", function (d) { return d.x1 - 20; })
            .attr("class", "flomaticText")
            .attr("text-anchor", "end");
        // });

        //creating top heading: 
        select('.node:nth-child(1) text').attr("dy", (d) => { this.leftTop = d.x1 + 60 });
        select('.node:nth-child(14) text').attr("dy", (d) => { this.centerTop = d.x1 + 40 });
        select('.node:nth-child(60) text').attr("dy", (d) => { this.rightTop = d.x1 });

        var _line = line();
        var points = [
            [-80, 28],
            [width + 80, 28]];
        var pathData = _line(points);
        select('svg').append('path').attr('d', pathData).attr("class", "titleHeadingBar").attr("stroke", "steelblue")
            .attr("stroke-width", function (d) { return 1; });
        select('svg')
            .append("text")
            .attr("x", this.leftTop)
            .attr("y", 15)
            .attr("dy", ".35em")
            .attr("text-anchor", "end")
            .text("SDGs");
        select('svg')
            .append("text")
            .attr("x", this.centerTop)
            .attr("y", 15)
            .attr("dy", ".35em")
            .attr("text-anchor", "middle")
            .text("SDG Targets");
        select('svg')
            .append("text")
            .attr("x", this.rightTop)
            .attr("y", 15)
            .attr("dy", ".35em")
            .attr("text-anchor", "start")
            .text("CSR-S Monitor Topics");

    }

    render() {
        return (
            <div><h3>Methodology Map</h3><hr /><div id="FlowOMaticChart"></div>
            </div>
        );
    }
}

export default FlowOMatic;

//example here: https://observablehq.com/@laga/flow-o-matic-authors-middle
//need to format the csv for this to work