
import React, { Component } from "react";
import CompaniesJSON from '../JsonData/CompanyList.json';
import NoCompanyFound from '../Components/NoCompanyFound';

import AsterChart from '../Components/AsterChart';
import ClusterBarChart from '../Components/ClusterBarChart';
import ComparisonTool from '../Components/ComparisonTool';

import ZoomableSunburstChart from "../Components/ZoomableSunburstChart";
import SDGTiles from '../Components/SDGTiles';

import '../Stylesheets/CompanyProfilePage.scss';


class CompanyProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      topcis: {
        "Emissions": 0,
        "Biodiversity/Land Use": 0,
        "Waste Management and Recycling (Output-Based)": 0,
        "Water Management (Input)": 0,
        "Energy Use (Input, Non-Transport Related)": 0,
        "Fuel Efficiency for Transportation (All Modes)": 0,
        "Other Resource Efficiency": 0,
        "Toxic Spills or Releases": 0,
        "Climate Change": 0,
        "Carbon Offsets/Credits/Emissions Trading": 0,
        "Product Innovation / Eco-Design": 0,
        "Process Innovation": 0,
        "Environmental Partnerships": 0,
        "Environmental Management Systems": 0,
        "Sustainable Buildings": 0,
        "Sustainable Material Use": 0,
        "Sustainable Sourcing": 0,
        "Animal Testing": 0,
        "Renewable Energy Use/Research": 0,
        "Sustainable Investing": 0,
        "Sustainable Packaging": 0
      },
      sdgs: {
        "1 - No Poverty": 0,
        "2 - Zero Hunger": 0,
        "3 - Good Health & Well Being": 0,
        "6 - Clean Water & Sanitation": 0,
        "7 - Affordable & Clean Energy": 0,
        "8 - Decent Work & Economic Growth": 0,
        "9 - Industry, Innovation, & Infrastructure": 0,
        "10 - Reduced Inequalities": 0,
        "11 - Sustainable Cities & Communities": 0,
        "12 - Responsible Consumption & Production": 0,
        "13 - Climate Action": 0,
        "14 - Life Below Water": 0,
        "15 - Life on Land": 0,
        "Overall SDG Score": 0
      },
      jsonData: this._prepareJSON(),
      cname: "",
      region: "",
      industry: "",
      html: ""
    };
  }

  componentDidMount() {
    if (this.state.jsonData[0]) {
      this.setState({ html: this._prepareCompanyInfo(this.state.jsonData[0]) })
      this._prepareSDGs(this.state.jsonData[0]);
      this._prepareTopics(this.state.jsonData[0]);
      this.setState({ isLoading: false });

    }
    else return this.setState({ html: <NoCompanyFound searched={this.props.cid} /> })

  }

  _prepareJSON = () => {
    // eslint-disable-next-line
    return CompaniesJSON.filter(data => {
      if (data.CID === (+this.props.cid)) {
        return data;
      }
    });
  }
  _prepareCompanyInfo = (data) => {
    this.setState({ cname: data.Cname, region: data.Region, industry: data.Industry });
    return (<div className="CompanyPageWrapper">
      <br /><h3> {data.Cname}</h3><hr />
      <div className="CompanyInfo_container">
        <div id="cind" className="row"><div>Industry:</div><div>{data.Industry}</div></div><div id="creg" className="row"><div>Region:</div><div>{data.Region}</div></div><div id="ccon" className="row"><div>Country:</div><div>{data.Country}</div></div>
      </div>
    </div>)
  }
  _prepareTopics = (_data) => {
    this.setState({
      topics: {
        "Emissions": _data.topic1,
        "Biodiversity/Land Use": _data.topic2,
        "Waste Management and Recycling (Output-Based)": _data.topic3,
        "Water Management (Input)": _data.topic4,
        "Energy Use (Input, Non-Transport Related)": _data.topic5,
        "Fuel Efficiency for Transportation (All Modes)": _data.topic6,
        "Other Resource Efficiency": _data.topic7,
        "Toxic Spills or Releases": _data.topic8,
        "Climate Change": _data.topic9,
        "Carbon Offsets/Credits/Emissions Trading": _data.topic10,
        "Product Innovation / Eco-Design": _data.topic11,
        "Process Innovation": _data.topic12,
        "Environmental Partnerships": _data.topic13,
        "Environmental Management Systems": _data.topic14,
        "Sustainable Buildings": _data.topic15,
        "Sustainable Material Use": _data.topic16,
        "Sustainable Sourcing": _data.topic17,
        "Animal Testing": _data.topic18,
        "Renewable Energy Use/Research": _data.topic19,
        "Sustainable Investing": _data.topic20,
        "Sustainable Packaging": _data.topic21
      }
    });
  }

  _prepareSDGs = (_data) => {
    this.setState({
      sdgs: {
        "1 - No Poverty": _data.sdg1,
        "2 - Zero Hunger": _data.sdg2,
        "3 - Good Health & Well Being": _data.sdg3,
        "6 - Clean Water & Sanitation": _data.sdg6,
        "7 - Affordable & Clean Energy": _data.sdg7,
        "8 - Decent Work & Economic Growth": _data.sdg8,
        "9 - Industry, Innovation, & Infrastructure": _data.sdg9,
        "10 - Reduced Inequalities": _data.sdg10,
        "11 - Sustainable Cities & Communities": _data.sdg11,
        "12 - Responsible Consumption & Production": _data.sdg12,
        "13 - Climate Action": _data.sdg13,
        "14 - Life Below Water": _data.sdg14,
        "15 - Life on Land": _data.sdg15,
        "Overall SDG Score": _data.overall
      }
    });
  }
  _readyToRender = () => {
    return (<div>
      {this.state.html}

      <br /><br />
      <h3>Company Overview</h3>
      <hr />
      <p>
        This chart shows the relative weight of each of the SDGs used to calculate a company’s overall envSDG score (width of the pie slice) and this company’s score on each SDG (height of each bar). The company’s overall envSDG score is displayed in the center of the chart. Mouseover or tap on a section of the chart to see more detail.</p><br />
      <AsterChart width={500} height={500} sdgs={this.state.sdgs} />
      <br /><br /><br /><br />
      <ClusterBarChart width={1000} height={650} ind={this.state.industry} reg={this.state.region} cname={this.state.cname} sdgs={this.state.sdgs} />
      <hr /><br />
      <ComparisonTool current={this.props.cid} curData={this.state.jsonData} data={CompaniesJSON} />
      <br /><hr /><br />
      <h3>Detailed Scoring Breakdown | {this.state.cname}</h3><hr />
      <p>This chart breaks down each component of a company’s overall envSDG score. Their score (displayed in the center) is scaled to be 100%, and the width of each layer is scaled to the correct ratio of its contribution to this score.
      Select an SDG (in the first layer) to zoom in for the detailed breakdown of the CSR-S Monitor topics and their relative contributions to the score for that Goal for the current company. Click in the middle of the circle to zoom back out to the big picture overview.
</p>
      <br /> <br />
      <ZoomableSunburstChart width={700} height={700} topics={this.state.topics} ind={this.state.industry} reg={this.state.region} cname={this.state.cname} sdgs={this.state.sdgs} />
      <br />
      <SDGTiles /> <br /> <br />
    </div>);
  }
  render() {
    return (
      <div>
        {
          this.state.isLoading ? ("LOADING") : (this._readyToRender())
        }
      </div >
    )
  }
}

export default CompanyProfilePage;