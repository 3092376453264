import React, {Component} from "react";


class AboutPage extends Component {
    render() {
        return (
<div className="contentDiv">
    <div className="field field--name-body field--type-text-with-summary field--label-hidden field--items">
            <div property="schema:text" className="field--item"><h2 className="nav_anchor" id="h2_top" title="About">CSR-Sustainability Monitor</h2>
                <h2>An Analytic Tool for Comparing Corporate Social Responsibility Reports</h2>
                <p>&nbsp;</p>
                <p>Baruch College's <a href="https://zicklin.baruch.cuny.edu/centers/weissman/">Weissman Center for International Business</a> created the CSR-Sustainability Monitor as a tool to analyze corporate social responsibility reporting from the largest U.S. and international companies. The CSR-Sustainability Monitor measures the scope and quality of the information provided by a company in its CSR reporting only. It does not represent an assessment or ranking of a company's actual performance or activities in the area of corporate social responsibility.</p>
                <p>The effectiveness of a company's corporate social responsibility reporting depends, to a large extent, on the level of credibility that the company's important stakeholders attach to it. That is why the CSR-Sustainability Monitor, in its screening process, also measures the degree to which the reporting company provides integrity assurance as to the accuracy and completeness of the information it is disclosing.</p>
                <p>&nbsp;</p>
                <hr/><h2 className="nav_anchor" id="h2_news" title="In The News">What others are saying about the Monitor</h2>
                <p><b><i>CSR-S Monitor in the Institutional Investor</i></b><br/>
                A new working paper from two finance experts at Baruch College's Zicklin School of Business in New York adds to the good news. The researchers have found that firms operating in environmentally and socially sensitive industries such as oil and gas extraction, mining and weapons production enjoy significantly higher stock market valuations when they issue comprehensive CSR reports. Read more about it&nbsp;<a href="http://www.institutionalinvestor.com/article/3475103/banking-and-capital-markets-corporations/csr-reporting-is-on-the-rise-and-so-is-its-impact.html#.VbjQtLXu540" target="_blank" rel="noreferrer">here</a><br/><br/><b><i>CSR-S Monitor article in SocialFunds</i></b><br/>
                "Rankings of 2014 Corporate Social Responsibility Reports Are Published" by Robert Kropp. Read it <a href="http://socialfunds.com/news/article.cgi/4162.html" target="_blank" rel="noreferrer">here</a><br/><br/><b><i>CSR-S Monitor article in My Social Good News</i></b><br/>
                "Baruch College Provides Industry-Based Rankings Of Corporate Social Responsibility Reports" By Api Podder. Read it&nbsp;<a href="http://mysocialgoodnews.com/baruch-college-provides-industry-based-rankings-of-corporate-social-responsibility-reports/" target="_blank" rel="noreferrer">here</a><br/><br/><b><i>CSR-S Monitor Named a PR News Award Finalist</i></b><br/>
                The CSR-Sustainability Monitor has been named a finalist for a CSR and Nonprofit PR News Award. The winners of the awards will be saluted at an Awards Luncheon on March 12, 2015 at The National Press Club in Washington, D.C.<br/><br/><b><i>Article in the CRA</i></b><br/>
                Corporate Responsibility Association has written an article about us, read more about it&nbsp;<a href="http://croassociation.org/content/weissman-center-international-businessthe-csr-sustainability-monitor%C2%AE" target="_blank" rel="noreferrer">here</a><br/><br/><b><i>PR Week (October 28, 2014 by Diana Bradley)</i></b><br/>
                PR Week included an article about the Monitor in its issue, read more about it&nbsp;<a href="http://www.prweek.com/article/1319117/companies-embrace-csr-cant-explain-it-baruch-college-study" target="_blank" rel="noreferrer">here</a></p>
                <p>&nbsp;</p>
                <hr/><p>&nbsp;</p>
                <h2 className="nav_anchor" id="h2_contact" title="Contact Us">Contact Us</h2>
                <h5><strong>For media questions</strong>:</h5>
                <p>Kirk Petersen, (646) 660-6141 or kirk.petersen@baruch.cuny.edu</p>
                <h5><strong>For other questions: </strong></h5>
                <p>646-312-2103 or CSR-S.Monitor@baruch.cuny.edu</p>
</div> 
</div>
</div>
        )
    }
}

export default AboutPage;