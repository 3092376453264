import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ModalPrompt extends Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Leaving page</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.close}>
                        Close
</Button>
                    <Button variant="primary" onClick={this.props.action}>
                        Proceed
</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalPrompt;