import React, { Component } from "react";
import CompaniesJSON from "../JsonData/CompaniesBySDG.json";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    ColumnToggle,
    Search,
    CSVExport,
} from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../Stylesheets/CompaniesBySDG.scss";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

//const products = CompaniesJSON;
const columns = [
    {
        dataField: "Company",
        text: "Company",
        sort: true,
    },
    {
        dataField: "envSDG Environment Score",
        text: "envSDG Score",
        sort: true,
    },
    {
        dataField: "envSDG Environment Rank",
        text: "envSDG Rank",
        sort: true,
    },
    {
        dataField: "CSR-S Monitor Environment Rank",
        text: "CSR-S Monitor Environment Rank",
        sort: true,
        hidden: true,
    },
];
const { ToggleList } = ColumnToggle;
const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;

const paginationOptions = {
    sizePerPageList: [
        {
            text: "10",
            value: 10,
        },
        {
            text: "25",
            value: 25,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "All",
            value: CompaniesJSON.length,
        },
    ],
};
class CompaniesBySDG extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            cname: "",
            cid: 0,
        };
    }

    componentDidMount() { }
    _clicked(row) {
        this.setState({ show: true, cname: row.Company, cid: row.CID });
    }
    handleClose = () => {
        this.setState({ show: false });
    };
    navigate = () => {
        this.props.history.push("/Company/" + this.state.cid);
    };
    render() {
        return (
            <div>
                <ToolkitProvider
                    keyField="CID"
                    data={CompaniesJSON}
                    columns={columns}
                    columnToggle
                    search
                    exportCSV={{
                        fileName: "CompaniesBySDG.csv",
                        ignoreHeader: false,
                        noAutoBOM: false,
                        exportAll: false,
                    }}
                >
                    {(props) => (
                        <div>
                            <p>Filter this list by Company Name:</p>
                            <SearchBar {...props.searchProps} />{" "}
                            <ClearSearchButton
                                {...props.searchProps}
                                className="btn btn-outline-dark"
                            />
                            <br />
                            <hr />
              Select Visible Columns{" "}
                            <ToggleList
                                {...props.columnToggleProps}
                                btnClassName="combysdgToggleBttn"
                                contextual="success"
                            />
                            <hr />
                            <BootstrapTable
                                bootstrap4={true}
                                {...props.baseProps}
                                striped
                                hover
                                condensed
                                pagination={paginationFactory(paginationOptions)}
                                rowEvents={{
                                    onClick: (e, row, rowIndex) => {
                                        this._clicked(row);
                                    },
                                }}
                                rowStyle={{ overflowWrap: "break-word" }}
                            />
                            <ExportCSVButton
                                {...props.csvProps}
                                className="btn btn-outline-dark"
                            >
                                Export shown data as CSV
              </ExportCSVButton>
                        </div>
                    )}
                </ToolkitProvider>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Leaving page</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Would you like to see {this.state.cname} company page?
          </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
            </Button>
                        <Button variant="primary" onClick={this.navigate}>
                            Proceed
            </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default CompaniesBySDG;
