import React, { Component } from "react";
import SDGTiles from '../Components/SDGTiles';
import CompaniesBySDG from '../Components/CompaniesBySDG';

import '../Stylesheets/SDGPage.scss';


class SDGPage extends Component {

    render() {
        return (
            <div className="sdgMainContainer">
                <h3>Company Rankings</h3><hr />
                <p>Below you will find a sortable list of all companies in our sample along with their envSDG score and relative ranking. Selecting a company will bring you to its profile page which includes more detail about the company’s background and a breakdown of their score. We have also included each company’s environment-related disclosure ranking on the CSR-S Monitor® as a toggleable addition.</p><br />
                <CompaniesBySDG history={this.props.history} />
                <hr /><SDGTiles /><br /><br />
            </div>
        )
    }
}

export default SDGPage;