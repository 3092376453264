import React, { Component } from "react";
import { interpolate, hierarchy, partition, arc } from "d3";
import { select, selectAll } from 'd3-selection';
// eslint-disable-next-line
//import transition from 'd3-transition';
//import { Sunburst } from "d3-sunburst";
import '../Stylesheets/SunburstChart.scss';
//import DataJSON from '../JsonData/SunburstChart.json';
//import testCSV from '../CsvData/SunburstTest.csv';

var height, width;
class ZoomableSunburstChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: (window.innerHeight > window.innerWidth) ? Math.floor(window.innerWidth * .9) : Math.floor(window.innerHeight * .9)
        }
        // eslint-disable-next-line
        var FormatArr = {};
        // eslint-disable-next-line
        var DataArr = {};
        // eslint-disable-next-line
        var CompanyName = "";
        // eslint-disable-next-line
        var CompanyScore = 0;
        this._generateCompanyData();
        //this._generateData(this.props.topics);
        this.data = this._generateData(this.props.topics);
    }

    _generateCompanyData = () => {
        // we take company data and merge it with dataJSON and populate new dataset
        this.FormatArr = { industry: this.props.ind, region: this.props.reg, aver: this.props.sdgs };
        this.CompanyName = this.props.cname;
        this.CompanyScore = this.props.sdgs["Overall SDG Score"];
    }
    updateDimensions() {
        var _w = (window.innerHeight > window.innerWidth) ? Math.floor(window.innerWidth * .9) : Math.floor(window.innerHeight * .9);
        this.setState({ width: _w });
    }
    componentDidMount() {
        this.updateDimensions();
        //this.drawChart();
        window.addEventListener("resize",
            this.updateDimensions.bind(this));
        this.drawChart(this.state.width);
    }
    componentWillUnmount() {
        window.removeEventListener("resize",
            this.updateDimensions.bind(this));
    }
    // componentDidUpdate() {
    //     // this.drawChart(this.state.width);
    // }
    drawChart(_w) {
        //select('.sunburstSVG').remove();
        width = _w;
        height = width;
        //this.height = this.props.height;
        this.radius = width / 6;
        const _radius = this.radius;
        const _arc = arc()
            .startAngle(d => d.x0)
            .endAngle(d => d.x1)
            .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(_radius * 1.5)
            .innerRadius(d => d.y0 * _radius)
            .outerRadius(d => Math.max(d.y0 * _radius, d.y1 * _radius - 1));
        //const format = d3format(",d");

        const root = this.partition(this.data);
        root.each(d => d.current = d);
        const svg = select("#ZoomableStarburstChart").append("svg")
            .attr("class", "sunburstSVG")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, width])
            .on("mouseleave", mouseleave);

        select("#ZoomableStarburstChart").append("div")
            .attr("class", "sunburst_tooltip");
        select("div.sunburst_tooltip")
            .append("div")
            .attr("class", "sunburst_ttip_innerTitle");
        select("div.sunburst_tooltip")
            .append("div")
            .attr("class", "sunburst_ttip_title")
            .text("Mouse over a segment to see detailed information");
        select("div.sunburst_tooltip")
            .append("div")
            .attr("class", "sunburst_ttip_inner");

        // .style("left", width + 20 + "px")
        // .style("top", width / 2 + "px")


        const g = svg.append("g")
            .attr("transform", `translate(${width / 2},${width / 2})`);
        const path = g.append("g")
            .attr("class", "sunburstSVGpath")
            .selectAll("path")
            .data(root.descendants().slice(1))
            .join("path")
            .attr("class", "sunburstSVGpath")
            .attr("fill", d => { while (d.depth > 1) d = d.parent; return d.data.color; })
            .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
            .attr("d", d => _arc(d.current))
            .on("mouseover", mouseover);

        path.filter(d => d.children)
            .style("cursor", "pointer")
            .on("click", clicked);

        path.append("title")
            .text(d => {
                if (d.ancestors()) d.ancestors().map(d => d.data.name).reverse().join(" / ");
            });

        const label = g.append("g")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none")
            .selectAll("text")
            .data(root.descendants().slice(1))
            .join("text")
            .attr("dy", "0.35em")
            .attr("class", "sunburstLables")
            .attr("fill-opacity", d => +labelVisible(d.current))
            .attr("transform", (d) => {
                const x = (d.current.x0 + d.current.x1) / 2 * 180 / Math.PI;
                const y = (d.current.y0 + d.current.y1) / 2 * _radius;
                return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
            })
            .text(d => parseNames(d.data.name));

        function parseNames(name) {
            var _name = name;
            switch (name) {
                case '1 - No Poverty':
                    _name = "SDG 1";
                    break;
                case '2 - Zero Hunger':
                    _name = "SDG 2";
                    break;
                case '3 - Good Health & Well Being':
                    _name = "SDG 3";
                    break;
                case '6 - Clean Water & Sanitation':
                    _name = "SDG 6";
                    break;
                case '7 - Affordable & Clean Energy':
                    _name = "SDG 7";
                    break;
                case '8 - Decent Work & Economic Growth':
                    _name = "SDG 8";
                    break;
                case '9 - Industry, Innovation, & Infrastructure':
                    _name = "SDG 9";
                    break;
                case '10 - Reduced Inequalities':
                    _name = "SDG 10";
                    break;
                case '11 - Sustainable Cities & Communities':
                    _name = "SDG 11";
                    break;
                case '12 - Responsible Consumption & Production':
                    _name = "SDG 12";
                    break;
                case '13 - Climate Action':
                    _name = "SDG 13";
                    break;
                case '14 - Life Below Water':
                    _name = "SDG 14";
                    break;
                case '15 - Life on Land':
                    _name = "SDG 15";
                    break;
                case 'Biodiversity/Land Use':
                    _name = "Biodiversity";
                    break;
                case 'Waste Management and Recycling (Output-Based)':
                    _name = "Waste Mgmt.";
                    break;
                case 'Water Management (Input)':
                    _name = "Water Use";
                    break;
                case 'Energy Use (Input, Non-Transport Related)':
                    _name = "Energy Use";
                    break;
                case 'Fuel Efficiency for Transportation (All Modes)':
                    _name = "Fuel Use (Transport)";
                    break;
                case 'Other Resource Efficiency':
                    _name = "Misc. Resource Use";
                    break;
                case 'Toxic Spills or Releases':
                    _name = "Env. Accidents/Leaks";
                    break;
                case 'Environmental Partnerships':
                    _name = "Env. Partnerships";
                    break;
                case 'Environmental Management Systems':
                    _name = "Env. Mgmt. Systems";
                    break;
                case 'Renewable Energy Use/Research':
                    _name = "Renewable Energy Use";
                    break;

                default:
                    return name;
            }
            return _name;
        }
        const parent = g.append("circle")
            .datum(root)
            .attr("r", _radius)
            .attr("fill", "none")
            .attr("pointer-events", "all")
            .on("mouseover", mouseleave)
            .on("click", clicked);

        svg.append("svg:text")
            .attr("class", "Sunburst-scoreDesc")
            .attr("x", ((width / 2) - _radius / 1.5))

            .attr("dy", 20)
            .style("font-size", "1em")
            .text("Overall envSDG Score")
            .call(wrap, 70)
            .attr("y",(width/2)-20);
        // svg.append("svg:text")
        //     .attr("class", "Sunburst-scoreDesc")
        //     .attr("x", ((width / 2) - _radius / 1.4))
        //     .attr("y", (width / 2) + _radius / 4)
        //     .attr("dy", ".45em")
        //     .style("font-size", "1.4em")
        //     .attr("text-anchor", "start") // text-align: right
        //     .text("envSDG");

        // svg.append("svg:text")
        //     .attr("class", "Sunburst-scoreDesc")
        //     .attr("x", ((width / 2) - _radius / 1.8))
        //     .attr("y", (width / 2) + _radius / 2.3)
        //     .attr("dy", ".45em")
        //     .style("font-size", "1.4em")
        //     .attr("text-anchor", "start") // text-align: right
        //     .text("Score");
        svg.append("svg:text")
            .attr("class", "Sunburst-score")
            .attr("x", (width / 2))
            .attr("y", (width / 2)+16)
            .style("font-size", "2em")
            .attr("text-anchor", "start") // text-align: right
            .text(updateTotals(this.CompanyScore, "root"));

        svg.append("svg:text")
            .attr("class", "Sunburst-childText")
            .attr("x", (width / 2))
            .attr("y", (width / 2))
            .attr("dy", ".35em")
            .style("font-size", ".9em")
            .style("color", "#00739A")
            .attr("text-anchor", "middle") // text-align: right
            .text("");
        svg.append("svg:text")
            .attr("class", "Sunburst-backbtnNote")
            .attr("x", (width / 2))
            .attr("y", (width / 2))
            .attr("dy", ".35em")
            .style("font-size", "1.4em")
            .style("color", "#00739A")
            .style("font-weight", 600)
            .attr("text-anchor", "middle") // text-align: right
            .text("");
        //mouse over
        // const percentage_text = svg.append("text")
        //     .attr("id", "title")
        //     .attr("x", (width / 2))
        //     .attr("y", ((width / 2) - _radius / 4))
        //     .attr("text-anchor", "middle")
        //     .style("font-size", "2.5em");
        const totalSize = root.descendants()[0].value;
        function updateTotals(val, id) {
            if (id === "root") return (val * 100).toPrecision(4) + "%";
            else return id + ": " + val + "%";
        }
        function wrap(text, width) {
            text.each(function() {
              var text = select(this),
                words = text.text().split(/\s+/).reverse(),
                word,
                line = [],
                //lineNumber = 0,
                lineHeight = 1.1, // ems
                y = text.attr("y"),
                x=text.attr("x"),
                dy = parseFloat(text.attr("dy")),
                tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy).attr("class", "Sunburst-tspan");
                // eslint-disable-next-line
              while (word = words.pop()) {
                line.push(word);
                tspan.text(line.join(" "));
                if (tspan.node().getComputedTextLength() > width) {
                  line.pop();
                  tspan.text(line.join(" "));
                  line = [word];
                  tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", lineHeight + "em").attr("class", "Sunburst-tspan").text(word);
                }
              }
            });}
        function mouseover(event, d) {
            var percentage = (100 * d.value / totalSize).toFixed(2);
            var percentageString = percentage + "%";
            if (percentage < 0.1) {
                percentageString = "< 0.1%";
            }
            //percentage_text.text(percentageString);
            var sequenceArray = d.ancestors().reverse();
            sequenceArray.shift(); // remove root node from the array
            // Fade all the segments.
            selectAll("path.sunburstSVGpath")
                .style("opacity", 0.3);

            // Then highlight only those that are an ancestor of the current segment.
            g.selectAll("path.sunburstSVGpath")
                .filter(function (node) {
                    return (sequenceArray.indexOf(node) >= 0);
                })
                .style("opacity", 1);
            select('.sunburst_ttip_title').html("" + d.data.name + "<hr style='margin:0;'/>");
            //parse additional info from txt field
            let _innerVals = percentageString, _innerVals2 = percentageString;
            if (d.data.txt) {
                _innerVals = d.data.txt.split("|")[0];
                _innerVals2 = d.data.txt.split("|")[1];
            }
            if (d.data.rawScore) {
                _innerVals = (d.data.rawScore * 100).toFixed(2) + "%"
            }
            if (!isNaN(d.data.name.charAt(0))) {
                select('.sunburst_ttip_inner').html("<div><span class='sunburst_ttip_title'>Company Score:</span><span class='sunburst_ttip_value'>" + _innerVals + "</span></div><div><span class='sunburst_ttip_title2'>Intensity Factor:</span><span class='sunburst_ttip_value'>" + (d.data.adj * 100).toFixed(2) + "%</span></div>");
            }
            else { select('.sunburst_ttip_inner').html("<div><span class='sunburst_ttip_title'>Topic Score:</span><span class='sunburst_ttip_value'>" + _innerVals + "</span></div><div><span class='sunburst_ttip_title2'>Topic Adjustment Factor:</span><span class='sunburst_ttip_value'>" + (_innerVals2 * 100).toFixed(2) + "%</span></div>"); }
        }
        //mouse leave 
        // Restore everything to full opacity when moving off the visualization.
        function mouseleave(d) {
            // Deactivate all segments during transition.
            //d3.selectAll("path").on("mouseover", null);

            // Transition each segment to full opacity and then reactivate it.
            selectAll("path.sunburstSVGpath")
                .transition("backtofull")
                .duration(200)
                .style("opacity", 1)
                .on("end", function () {
                    select(this).on("mouseover", mouseover);
                });

            select('.sunburst_ttip_title').text("Mouse over a segment to see detailed information");
            select('.sunburst_ttip_inner')
                .html("");
            //percentage_text.text("");
        }
        //const companyTotalScore = this.CompanyScore;
        function clicked(event, p) {
            parent.datum(p.parent || root);
            root.each(d => {
                d.target = {
                    x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                    x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                    y0: Math.max(0, d.y0 - p.depth),
                    y1: Math.max(0, d.y1 - p.depth)
                };
            });
            if (!p.parent) {
                selectAll('.Sunburst-scoreDesc').attr("opacity", 1);
                select('.Sunburst-score').attr("opacity", 1);
                //select('.Sunburst-score').text(updateTotals(companyTotalScore, "root"));
                select('.Sunburst-backbtnNote').text("");
                select('.sunburst_ttip_innerTitle')
                    .text("")
                    .style("border-bottom", "none");
            }
            else {
                selectAll('.Sunburst-scoreDesc').attr("opacity", 0);
                select('.Sunburst-score').attr("opacity", 0);
                // select('.Sunburst-childText').text(updateTotals((100 * p.value / totalSize).toPrecision(3), p.ancestors().map(d => d.data.name)[0]));
                select('.Sunburst-backbtnNote').text("Click to go back").style("pointer-events", "none").style("z-index", -2);
                select('.sunburst_ttip_innerTitle').html(updateTotals((100 * p.value / totalSize).toPrecision(3), p.ancestors().map(d => d.data.name)[0]) + "<br/>")
                    .style("border-bottom", "solid");
            }

            const t = g.transition("zoom").duration(750);

            // Transition the data on all arcs, even the ones that aren’t visible,
            // so that if this transition is interrupted, entering arcs will start
            // the next transition from the desired position.
            path.transition(t)
                .tween("data", d => {
                    const i = interpolate(d.current, d.target);
                    return t => d.current = i(t);
                })
                .filter(function (d) {
                    return +this.getAttribute("fill-opacity") || arcVisible(d.target);
                })
                .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
                .attrTween("d", d => () => _arc(d.current));

            label.filter(function (d) {
                return +this.getAttribute("fill-opacity") || labelVisible(d.target);
            }).transition(t)
                .attr("fill-opacity", d => +labelVisible(d.target))
                .attrTween("transform", (d) => () => {
                    const x = (d.current.x0 + d.current.x1) / 2 * 180 / Math.PI;
                    const y = (d.current.y0 + d.current.y1) / 2 * _radius;
                    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
                });
            //  const totalSize = root.descendants()[0].value;
        }

        function arcVisible(d) {
            return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
        }

        function labelVisible(d) {
            return d.y1 <= 2 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03; //change "2" for max layer visibility
        }

        return svg.node();
    }
    partition = data => {
        const root = hierarchy(data)
            .sum(d => d.score)
            .sort((a, b) => b.score - a.score);
        return partition()
            .size([2 * Math.PI, root.height + 1])
            (root);
    }

    _generateData = (cinfo) => {
        var max_scr = 260.58;
        var adj1 = (1 / 46), if_sdg1 = adj1 / (23 / max_scr);
        var adj2 = (1 / 46), if_sdg2 = adj2 / (34 / max_scr);
        var adj3 = (1 / 46), if_sdg3 = adj3 / (44 / max_scr);
        var adj6 = (5 / 46), if_sdg6 = adj6 / (15.6 / max_scr);
        var adj7 = (3 / 46), if_sdg7 = adj7 / (8.33 / max_scr);
        var adj8 = (1 / 46), if_sdg8 = adj8 / (44 / max_scr);
        var adj9 = (3 / 46), if_sdg9 = adj9 / (16 / max_scr);
        var adj10 = (1 / 46), if_sdg10 = adj10 / (2 / max_scr);
        var adj11 = (6 / 46), if_sdg11 = adj11 / (12 / max_scr);
        var adj12 = (7 / 46), if_sdg12 = adj12 / (14.86 / max_scr);
        var adj13 = (3 / 46), if_sdg13 = adj13 / (15.67 / max_scr);
        var adj14 = (6 / 46), if_sdg14 = adj14 / (18.5 / max_scr);
        var adj15 = (8 / 46), if_sdg15 = adj15 / (12.63 / max_scr);

        return this.DataArr = {
            "name": "Goals",
            "children": [
                {
                    "name": "1 - No Poverty",
                    "color": "#E5243B",
                    "adj": adj1,
                    "rawScore": this.FormatArr.aver["1 - No Poverty"],
                    "children": [

                        {
                            "name": "Emissions",
                            "score": (cinfo["Emissions"] * if_sdg1), "txt": cinfo["Emissions"] + " of 11|1"
                        },
                        {
                            "name": "Toxic Spills or Releases",
                            "score": (cinfo["Toxic Spills or Releases"] * if_sdg1), "txt": cinfo["Toxic Spills or Releases"] + " of 11|1"
                        },
                        {
                            "name": "Climate Change",
                            "score": (cinfo["Climate Change"] * if_sdg1), "txt": cinfo["Climate Change"] + " of 1|1"
                        }
                    ]
                },
                {
                    "name": "2 - Zero Hunger",
                    "color": "#DDA63A",
                    "adj": adj2,
                    "rawScore": this.FormatArr.aver["2 - Zero Hunger"],
                    "children": [
                        {

                            "name": "Emissions",
                            "score": (cinfo["Emissions"] * if_sdg2), "txt": cinfo["Emissions"] + " of 11|1"
                        },
                        {
                            "name": "Biodiversity/Land Use",
                            "score": (cinfo["Biodiversity/Land Use"] * if_sdg2), "txt": cinfo["Biodiversity/Land Use"] + " of 11|1"
                        },
                        {
                            "name": "Toxic Spills or Releases",
                            "score": (cinfo["Toxic Spills or Releases"] * if_sdg2), "txt": cinfo["Toxic Spills or Releases"] + " of 11|1"
                        },
                        {
                            "name": "Climate Change",
                            "score": (cinfo["Climate Change"] * if_sdg2), "txt": cinfo["Climate Change"] + " of 1|1"
                        }

                    ]
                },
                {
                    "name": "3 - Good Health & Well Being",
                    "color": "#4C9F38",
                    "adj": adj3,
                    "rawScore": this.FormatArr.aver["3 - Good Health & Well Being"],
                    "children": [
                        {
                            "name": "Emissions",
                            "score": (cinfo["Emissions"] * if_sdg3), "txt": cinfo["Emissions"] + " of 11|1"
                        },
                        {
                            "name": "Toxic Spills or Releases",
                            "score": (cinfo["Toxic Spills or Releases"] * if_sdg3), "txt": cinfo["Toxic Spills or Releases"] + " of 11|1"
                        },
                        {
                            "name": "Waste Management and Recycling (Output-Based)",
                            "score": (cinfo["Waste Management and Recycling (Output-Based)"] * if_sdg3), "txt": cinfo["Waste Management and Recycling (Output-Based)"] + " of 11|1"
                        },
                        {
                            "name": "Water Management (Input)",
                            "score": (cinfo["Water Management (Input)"] * if_sdg3), "txt": cinfo["Water Management (Input)"] + " of 11|1"
                        }

                    ]
                }, {
                    "name": "6 - Clean Water & Sanitation",
                    "color": "#26BDE2",
                    "adj": adj6,
                    "rawScore": this.FormatArr.aver["6 - Clean Water & Sanitation"],
                    "children": [
                        {
                            "name": "Water Management (Input)",
                            "score": (cinfo["Water Management (Input)"] * .8 * if_sdg6), "txt": cinfo["Water Management (Input)"] + " of 11|.8"
                        },
                        {
                            "name": "Toxic Spills or Releases",
                            "score": (cinfo["Toxic Spills or Releases"] * .2 * if_sdg6), "txt": cinfo["Toxic Spills or Releases"] + " of 11|.2"
                        },
                        {
                            "name": "Waste Management and Recycling (Output-Based)",
                            "score": (cinfo["Waste Management and Recycling (Output-Based)"] * .2 * if_sdg6), "txt": cinfo["Waste Management and Recycling (Output-Based)"] + " of 11|.2"
                        }, {
                            "name": "Environmental Management Systems",
                            "score": (cinfo["Environmental Management Systems"] * .2 * if_sdg6), "txt": cinfo["Environmental Management Systems"] + " of 1|.2"
                        },
                        {
                            "name": "Biodiversity/Land Use",
                            "score": (cinfo["Biodiversity/Land Use"] * .2 * if_sdg6), "txt": cinfo["Biodiversity/Land Use"] + " of 11|.2"
                        }
                    ]
                },
                {
                    "name": "7 - Affordable & Clean Energy",
                    "color": "#FCC30B",
                    "adj": adj7,
                    "rawScore": this.FormatArr.aver["7 - Affordable & Clean Energy"],
                    "children": [
                        {
                            "name": "Energy Use (Input, Non-Transport Related)",
                            "score": (cinfo["Energy Use (Input, Non-Transport Related)"] * (2 / 3) * if_sdg7), "txt": cinfo["Energy Use (Input, Non-Transport Related)"] + " of 11|.6667"
                        },
                        {
                            "name": "Renewable Energy Use/Research",
                            "score": (cinfo["Renewable Energy Use/Research"] * if_sdg7), "txt": cinfo["Renewable Energy Use/Research"] + " of 1|1"
                        }
                    ]
                },
                {
                    "name": "8 - Decent Work & Economic Growth",
                    "color": "#A21942",
                    "adj": adj8,
                    "rawScore": this.FormatArr.aver["8 - Decent Work & Economic Growth"],
                    "children": [
                        {
                            "name": "Energy Use (Input, Non-Transport Related)",
                            "score": (cinfo["Energy Use (Input, Non-Transport Related)"] * if_sdg8), "txt": cinfo["Energy Use (Input, Non-Transport Related)"] + " of 11|1"
                        },
                        {
                            "name": "Fuel Efficiency for Transportation (All Modes)",
                            "score": (cinfo["Fuel Efficiency for Transportation (All Modes)"] * if_sdg8), "txt": cinfo["Fuel Efficiency for Transportation (All Modes)"] + " of 11|1"
                        },
                        {
                            "name": "Other Resource Efficiency",
                            "score": (cinfo["Other Resource Efficiency"] * if_sdg8), "txt": cinfo["Other Resource Efficiency"] + " of 11|1"
                        },
                        {
                            "name": "Water Management (Input)",
                            "score": (cinfo["Water Management (Input)"] * if_sdg8), "txt": cinfo["Water Management (Input)"] + " of 11|1"
                        }

                    ]
                },
                {
                    "name": "9 - Industry, Innovation, & Infrastructure",
                    "color": "#FD6925",
                    "adj": adj9,
                    "rawScore": this.FormatArr.aver["9 - Industry, Innovation, & Infrastructure"],
                    "children": [
                        {

                            "name": "Sustainable Buildings",
                            "score": (cinfo["Sustainable Buildings"] * (2 / 3) * if_sdg9), "txt": cinfo["Sustainable Buildings"] + " of 1|.6667"
                        },
                        {
                            "name": "Sustainable Material Use",
                            "score": (cinfo["Sustainable Material Use"] * (1 / 3) * if_sdg9), "txt": cinfo["Sustainable Material Use"] + " of 1|.3333"

                        },
                        {

                            "name": "Sustainable Sourcing",
                            "score": (cinfo["Sustainable Sourcing"] * (1 / 3) * if_sdg9), "txt": cinfo["Sustainable Sourcing"] + " of 1|.3333"

                        },
                        {

                            "name": "Water Management (Input)",
                            "score": (cinfo["Water Management (Input)"] * (1 / 3) * if_sdg9), "txt": cinfo["Water Management (Input)"] + " of 11|.3333"
                        },
                        {
                            "name": "Energy Use (Input, Non-Transport Related)",
                            "score": (cinfo["Energy Use (Input, Non-Transport Related)"] * (1 / 3) * if_sdg9), "txt": cinfo["Energy Use (Input, Non-Transport Related)"] + " of 11|.3333"
                        }, {
                            "name": "Other Resource Efficiency",
                            "score": (cinfo["Other Resource Efficiency"] * (1 / 3) * if_sdg9), "txt": cinfo["Other Resource Efficiency"] + " of 11|.3333"
                        }, {
                            "name": "Fuel Efficiency for Transportation (All Modes)",
                            "score": (cinfo["Fuel Efficiency for Transportation (All Modes)"] * (1 / 3) * if_sdg9), "txt": cinfo["Fuel Efficiency for Transportation (All Modes)"] + " of 11|.3333"
                        },
                    ]
                },
                {
                    "name": "10 - Reduced Inequalities",
                    "color": "#DD1367",
                    "adj": adj10,
                    "rawScore": this.FormatArr.aver["10 - Reduced Inequalities"],
                    "children": [
                        {
                            "name": "Climate Change",
                            "score": (cinfo["Climate Change"] * if_sdg10), "txt": cinfo["Climate Change"] + " of 1|1"
                        },
                        {
                            "name": "Environmental Partnerships",
                            "score": (cinfo["Environmental Partnerships"] * if_sdg10), "txt": cinfo["Environmental Partnerships"] + " of 1|1"
                        }

                    ]
                },
                {
                    "name": "11 - Sustainable Cities & Communities",
                    "color": "#FD9D24",
                    "adj": adj11,
                    "rawScore": this.FormatArr.aver["11 - Sustainable Cities & Communities"],
                    "children": [

                        {
                            "name": "Fuel Efficiency for Transportation (All Modes)",
                            "score": (cinfo["Fuel Efficiency for Transportation (All Modes)"] * (1 / 6) * if_sdg11), "txt": cinfo["Fuel Efficiency for Transportation (All Modes)"] + " of 11|.1667"
                        },
                        {
                            "name": "Sustainable Buildings",
                            "score": (cinfo["Sustainable Buildings"] * (3 / 6) * if_sdg11), "txt": cinfo["Sustainable Buildings"] + " of 1|.5"
                        },
                        {
                            "name": "Environmental Management Systems",
                            "score": (cinfo["Environmental Management Systems"] * (1 / 6) * if_sdg11), "txt": cinfo["Environmental Management Systems"] + " of 1|.1667"
                        },
                        {
                            "name": "Climate Change",
                            "score": (cinfo["Climate Change"] * (1 / 6) * if_sdg11), "txt": cinfo["Climate Change"] + " of 1|.1667"
                        },
                        {
                            "name": "Environmental Partnerships",
                            "score": (cinfo["Environmental Partnerships"] * (1 / 6) * if_sdg11), "txt": cinfo["Environmental Partnerships"] + " of 1|.1667"
                        },
                        {
                            "name": "Toxic Spills or Releases",
                            "score": (cinfo["Toxic Spills or Releases"] * (1 / 6) * if_sdg11), "txt": cinfo["Toxic Spills or Releases"] + " of 11|.1667"
                        },
                        {
                            "name": "Waste Management and Recycling (Output-Based)",
                            "score": (cinfo["Waste Management and Recycling (Output-Based)"] * (1 / 6) * if_sdg11), "txt": cinfo["Waste Management and Recycling (Output-Based)"] + " of 11|.1667"
                        },
                        {
                            "name": "Emissions",
                            "score": (cinfo["Emissions"] * (1 / 6) * if_sdg11), "txt": cinfo["Emissions"] + " of 11|.1667"
                        },
                        {
                            "name": "Biodiversity/Land Use",
                            "score": (cinfo["Biodiversity/Land Use"] * (2 / 6) * if_sdg11), "txt": cinfo["Biodiversity/Land Use"] + " of 11|.3333"
                        },
                    ]
                },
                {
                    "name": "12 - Responsible Consumption & Production",
                    "color": "#BF8B2E",
                    "adj": adj12,
                    "rawScore": this.FormatArr.aver["12 - Responsible Consumption & Production"],
                    "children": [
                        {
                            "name": "Sustainable Sourcing",
                            "score": (cinfo["Sustainable Sourcing"] * (4 / 7) * if_sdg12), "txt": cinfo["Sustainable Sourcing"] + " of 1|.5714"
                        },
                        {
                            "name": "Energy Use (Input, Non-Transport Related)",
                            "score": (cinfo["Energy Use (Input, Non-Transport Related)"] * (1 / 7) * if_sdg12), "txt": cinfo["Energy Use (Input, Non-Transport Related)"] + " of 11|.1429"
                        },
                        {
                            "name": "Fuel Efficiency for Transportation (All Modes)",
                            "score": (cinfo["Fuel Efficiency for Transportation (All Modes)"] * (1 / 7) * if_sdg12), "txt": cinfo["Fuel Efficiency for Transportation (All Modes)"] + " of 11|.1429"
                        },
                        {
                            "name": "Other Resource Efficiency",
                            "score": (cinfo["Other Resource Efficiency"] * (1 / 7) * if_sdg12), "txt": cinfo["Other Resource Efficiency"] + " of 11|.1429"
                        },
                        {
                            "name": "Waste Management and Recycling (Output-Based)",
                            "score": (cinfo["Waste Management and Recycling (Output-Based)"] * (3 / 7) * if_sdg12), "txt": cinfo["Waste Management and Recycling (Output-Based)"] + " of 11|.4286"
                        },
                        {
                            "name": "Emissions",
                            "score": (cinfo["Emissions"] * (1 / 7) * if_sdg12), "txt": cinfo["Emissions"] + " of 11|.1429"
                        },
                        {
                            "name": "Toxic Spills or Releases",
                            "score": (cinfo["Toxic Spills or Releases"] * (1 / 7) * if_sdg12), "txt": cinfo["Toxic Spills or Releases"] + " of 11|.1429"
                        },
                        {
                            "name": "Environmental Partnerships",
                            "score": (cinfo["Environmental Partnerships"] * (1 / 7) * if_sdg12), "txt": cinfo["Environmental Partnerships"] + " of 1|.1429"
                        },
                        {
                            "name": "Water Management (Input)",
                            "score": (cinfo["Water Management (Input)"] * (1 / 7) * if_sdg12), "txt": cinfo["Water Management (Input)"] + " of 11|.1429"
                        }
                    ]
                },
                {
                    "name": "13 - Climate Action",
                    "color": "#3F7E44",
                    "adj": adj13,
                    "rawScore": this.FormatArr.aver["13 - Climate Action"],
                    "children": [
                        {

                            "name": "Emissions",
                            "score": (cinfo["Emissions"] * if_sdg13), "txt": cinfo["Emissions"] + " of 11|1"
                        },
                        {
                            "name": "Toxic Spills or Releases",
                            "score": (cinfo["Toxic Spills or Releases"] * (1 / 3) * if_sdg13), "txt": cinfo["Toxic Spills or Releases"] + " of 11|.3333"
                        },
                        {
                            "name": "Climate Change",
                            "score": (cinfo["Climate Change"] * if_sdg13), "txt": cinfo["Climate Change"] + " of 1|1"

                        },
                    ]
                },
                {
                    "name": "14 - Life Below Water",
                    "color": "#0A97D9",
                    "adj": adj14,
                    "rawScore": this.FormatArr.aver["14 - Life Below Water"],
                    "children": [
                        {
                            "name": "Biodiversity/Land Use",
                            "score": (cinfo["Biodiversity/Land Use"] * if_sdg14), "txt": cinfo["Biodiversity/Land Use"] + " of 11|1"
                        },
                        {
                            "name": "Waste Management and Recycling (Output-Based)",
                            "score": (cinfo["Waste Management and Recycling (Output-Based)"] * (3 / 6) * if_sdg14), "txt": cinfo["Waste Management and Recycling (Output-Based)"] + " of 11|.5"
                        },
                        {
                            "name": "Environmental Management Systems",
                            "score": (cinfo["Environmental Management Systems"] * (1 / 6) * if_sdg14), "txt": cinfo["Environmental Management Systems"] + " of 1|.1667"
                        },
                        {
                            "name": "Water Management (Input)",
                            "score": (cinfo["Water Management (Input)"] * (1 / 6) * if_sdg14),
                            "txt": cinfo["Water Management (Input)"] + " of 11|.1667"
                        }
                    ]
                },
                {
                    "name": "15 - Life on Land",
                    "color": "#56C02B",
                    "adj": adj15,
                    "rawScore": this.FormatArr.aver["15 - Life on Land"],
                    "children": [
                        {
                            "name": "Biodiversity/Land Use",
                            "score": (cinfo["Biodiversity/Land Use"] * if_sdg15),
                            "txt": cinfo["Biodiversity/Land Use"] + " of 11|1"
                        },
                        {
                            "name": "Water Management (Input)",
                            "score": (cinfo["Water Management (Input)"] * (1 / 8) * if_sdg15),
                            "txt": cinfo["Water Management (Input)"] + " of 11|.125"
                        },
                        {
                            "name": "Environmental Management Systems",
                            "score": (cinfo["Environmental Management Systems"] * (1 / 8) * if_sdg15),
                            "txt": cinfo["Environmental Management Systems"] + " of 1|.125"
                        },
                        {
                            "name": "Climate Change",
                            "score": (cinfo["Climate Change"] * (1 / 8) * if_sdg15),
                            "txt": cinfo["Climate Change"] + " of 1|.125"
                        },
                    ]
                }
            ]
        };
    }
    render() {
        return (
            <div id="ZoomableStarburstChart"></div>
        );
    }
}

export default ZoomableSunburstChart;