import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import AutocompletionSearch from '../Components/AutocompletionSearch'


import SdgGoal from './SdgGoal';
import { Sdg1, Sdg2, Sdg3, Sdg6, Sdg7, Sdg8, Sdg9, Sdg10, Sdg11, Sdg12, Sdg13, Sdg14, Sdg15 } from '../Images/SDG';
import "../Stylesheets/ComparisonTool.scss";

const goals = [Sdg1, Sdg2, Sdg3, Sdg6, Sdg7, Sdg8, Sdg9, Sdg10, Sdg11, Sdg12, Sdg13, Sdg14, Sdg15];
const goalsKeys = ["sdg1", "sdg2", "sdg3", "sdg6", "sdg7", "sdg8", "sdg9", "sdg10", "sdg11", "sdg12", "sdg13", "sdg14", "sdg15"];
//data imported via props (CompaniesJSON) this.props.data
let targetData = {};
let currData = {};
class ComparisonTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            cid: this.props.current,
            target: 'testCompany',
            cname: "testTarget",
            loaded: false,
            processing: false,
        }
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);

    }
    componentDidMount() {
        currData = this.props.curData[0];
    }
    handleOpen() {
        this.setState({ show: true, cname: "currCompany", cid: "selected ID" });
    }
    handleClose = () => {
        this.setState({ show: false });
    };
    compare = () => {
        this.setState({ show: false, loaded: true });
    };
    reset = () => {
        targetData = {};
        this.setState({ loaded: false, target: '', processing: false, })
    }
    processResults = (res) => {
        //console.log("processing: ", res);
        targetData = res;
        this.setState({ target: res.Cname, processing: true });
    }
    resetSelection = () => {
        targetData = {};
        this.setState({ target: '', processing: false, loaded: false });
    }
    render() {
        return (
            <div>
                <Button hidden={this.state.loaded} variant="secondary" onClick={this.handleOpen}>
                    Comparison Tool
                    </Button>
                <Button hidden={!this.state.loaded} variant="secondary" onClick={this.reset}>
                    reset
                    </Button>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Comparison Tool</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Compare {currData.Cname} with:
                        {(this.state.processing) ? (<span> <b>{this.state.target}</b></span>) : <AutocompletionSearch data={this.props.data} searchCallback={this.processResults} />}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                    </Button>
                        <Button hidden={!this.state.processing} variant="secondary" onClick={this.resetSelection}>
                            Reset Selection
                    </Button>
                        <Button disabled={!this.state.processing} variant="primary" onClick={this.compare}>
                            Compare
            </Button>
                    </Modal.Footer>
                </Modal>
                {(this.state.loaded ? (
                    <Table className="" responsive="sm">
                        <thead>
                            <tr className="ct_tableHeading">
                                <th></th>
                                <th>{currData.Cname}</th>
                                <th></th>
                                <th>{targetData.Cname}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Industry</td>
                                <td>{currData.Industry}</td><td>---</td><td>{targetData.Industry}</td>
                            </tr>
                            <tr>
                                <td>Region</td>
                                <td>{currData.Region}</td><td>---</td><td>{targetData.Region}</td>
                            </tr>
                            <tr>
                                <td>Country</td>
                                <td>{currData.Country}</td><td>---</td><td>{targetData.Country}</td>
                            </tr>
                            {goals.map((goal, index) => <tr key={index}>
                                <td><SdgGoal cl="ComparisonTool_SDGIcon" src={goal} /></td>
                                <td>{(currData[goalsKeys[index]] * 100).toFixed(2)}%</td><td>{(currData[goalsKeys[index]] > targetData[goalsKeys[index]]) ? ">" : (currData[goalsKeys[index]] === targetData[goalsKeys[index]]) ? "=" : "<"}</td><td>{(targetData[goalsKeys[index]] * 100).toFixed(2)}%</td>
                            </tr>)}
                            <tr>
                                <td>Overall envSDG Score</td>
                                <td>{(currData.overall * 100).toFixed(2)}%</td><td>{(currData.overall > targetData.overall) ? ">" : (currData.overall === targetData.overall) ? "=" : "<"}</td><td>{(targetData.overall * 100).toFixed(2)}%</td>
                            </tr>
                        </tbody>

                    </Table>
                ) : (
                        ""
                    ))}

            </div>
        );
    }
}

export default ComparisonTool;
// React-Search-Autocomplete
// Results.js                       change name to Cname
// }, showIcon && _react["default"].createElement("div", {
//     className: "icon"
//   }, _react["default"].createElement(_SearchIcon.SearchIcon, null)), _react["default"].createElement("div", {
//     className: "ellipsis",
//     title: result.Cname
//   }, result.Cname)); 
// }))) : null;
// }