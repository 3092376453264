import React, { Component } from "react";
import SDGsByIndustry from '../Components/SDGsByIndustry';
import TopIndSDG from '../Components/TopIndSDG';
import SDGTiles from '../Components/SDGTiles';


class SdgsbyIndustryPage extends Component {
    render() {
        return (
            <div>
                <h3>Industry-based Findings</h3>
                <hr />
                <div>
                    <ul>
                        <li>
                            Our industry findings highlight broad trends in companies’ alignment with SDGs across industries. Goods-producing supersector averages are consistently higher than service-providing supersector averages. Individual industry rankings echo these findings with two Goods-Producing industries, Extractives and Construction, taking the top two spots.
                        </li>
                        <li>
                            Miscellaneous Services, Finance and Insurance, and Wholesale Trade are the three industries with the lowest overall averages, and all belong to the service-providing supersector.
                        </li>
                        <li>
                            In contrast, Transportation, another service-providing industry, stands out as the third overall ranked industry.
                        </li>
                        <li>
                            See the Executive Summary report (hyperlink: TBD) for more findings and discussions.
                        </li>
                    </ul>

                </div>

                <br /><br />
                <p>The following table shows the average envSDG scores for the Goods-Producing and Service-Providing supersectors ¹ ²</p>
                <SDGsByIndustry />
                <br /><br /><br />
                <div>
                    <h3>Top 3 Industries by envSDG</h3>
                    <hr />
                    <ul>
                        <li>
                            Overall, industries with the largest environmental footprints tend to have higher average envSDG scores. Transportation appears most frequently in the Top 3 industries by average score for each SDG and the overall score (12 out of 14), followed by the Extractives and Construction industries, each appearing 11 and 10 times, respectively.
                                                </li>
                        <li>
                            On the other hand, Finance and Insurance, Retail Trade, Wholesale Trade, and Information do not appear in the Top 3 of any SDG, while Manufacturing-31/32/33 do show up in a few Top 3 categories, but not frequently as one may expect given their significant environmental impacts.
                        </li>
                        <li>
                            Other noteworthy industries are Utilities (three Top 3 appearances), and Professional Services and Manufacturing-31 (each with two Top 3 appearances).
                        </li>
                        <li>
                            See the Executive Summary report (hyperlink: TBD) for more findings and discussions.
                        </li>
                    </ul>
                </div>
                <br /><br />
                <TopIndSDG />
                <hr />
                <div className="chart_footnote">
                    Footnote 1: These industry names are shortened for space. Their full names are:
                    <ul>
                        <li>Mining, Quarrying, and Oil and Gas Extraction-21</li><li>Transportation and Warehousing-48, 49</li><li>Professional, Scientific, and Technical Services-54</li><li>Management of Companies and Enterprises-55</li><li>Administrative and Support and Waste Management and Remediation Services-56</li>
                    </ul>
                </div>
                <div className="chart_footnote">Footnote 2: Note that SDGs 4, 5, 16, and 17 are excluded as they do not have environmentally-related targets that we could score.
                </div>
                <div className="chart_footnote">Footnote 3: Supersector and overall sample size numbers include all industries, even those with a sample size of 1 which are not displayed on the table otherwise.
                </div>
                <div className="chart_footnote">Footnote 4:
                There are three manufacturing industries: 31, 32, and 33. They are categorized by product type.
                    <ul><li>Manufacturing-31 includes food, beverages, tobacco, textiles, fabric, and apparel manufacturing</li>
                        <li>Manufacturing-32 includes wood, paper and printing, petroleum and coal products, chemicals (including pharmaceuticals), plastics and rubber, and nonmetallic mineral manufacturing</li>
                        <li>Manufacturing-33 includes metals, machinery, electronics and electrical equipment, transportation equipment, furniture, and other miscellaneous manufacturing</li></ul>
                </div><br />
                <SDGTiles />
                <br />
            </div>
        )
    }
}

export default SdgsbyIndustryPage;