import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import SdgGoal from '../Components/SdgGoal';
import { Sdg1, Sdg2, Sdg3, Sdg6, Sdg7, Sdg8, Sdg9, Sdg10, Sdg11, Sdg12, Sdg13, Sdg14, Sdg15 } from '../Images/SDG';

import '../Stylesheets/TopIndSDG.scss';

class TopIndSDG extends Component {
    render() {
        return (
            <div className="column">
                <p className="row no-gutters">The following table identifies the three highest-scoring industries on each SDG.</p><hr />
                <div className="row">
                    <div className="column sdgTopTable">
                        <Table className="TopIndSDG_table" responsive="sm">
                            <thead>
                                <tr>
                                    <th>SDG</th>
                                    <th>Industry</th>
                                    <th className="TopIndSDG_th">Average envSDG Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg1} /></td>
                                    <td>Extractives</td>
                                    <td>48.03%</td>
                                </tr>
                                <tr>
                                    <td>Transportation</td>
                                    <td>42.03%</td>
                                </tr><tr>
                                    <td>Utilities</td>
                                    <td>39.59%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg2} /></td>
                                    <td>Extractives</td>
                                    <td>49.44%</td>
                                </tr>
                                <tr>

                                    <td>Construction</td>
                                    <td>41.76%</td>
                                </tr>
                                <tr>

                                    <td>Transportation</td>
                                    <td>37.26%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg3} /></td>
                                    <td>Extractives</td>
                                    <td>47.29%</td>
                                </tr>
                                <tr>

                                    <td>Construction</td>
                                    <td>43.18%</td>
                                </tr><tr>

                                    <td>Manufacturing-32</td>
                                    <td>40.32%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg6} /></td>
                                    <td>Extractives</td>
                                    <td>47.01%</td>
                                </tr>
                                <tr>

                                    <td>Construction</td>
                                    <td>45.39%</td>
                                </tr>
                                <tr>
                                    <td>Manufacturing-31</td>
                                    <td>38.25%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg7} /></td>
                                    <td>Construction</td>
                                    <td>57.60%</td>
                                </tr>
                                <tr>

                                    <td>Transportation</td>
                                    <td>54.00%</td>
                                </tr><tr>
                                    <td>Utilities</td>
                                    <td>53.89%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg8} /></td>
                                    <td>Transportation</td>
                                    <td>34.28%</td>
                                </tr>
                                <tr>
                                    <td>Extractives</td>
                                    <td>32.25%</td>
                                </tr>
                                <tr>
                                    <td>Manufacturing-33</td>
                                    <td>32.17%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg9} /></td>
                                    <td>Transportation</td>
                                    <td>35.94%</td>
                                </tr>
                                <tr>
                                    <td>Professional Services</td>
                                    <td>34.38%</td>
                                </tr>
                                <tr>
                                    <td>Construction</td>
                                    <td>33.75%</td>
                                </tr>
                            </tbody>
                        </Table></div>
                    <div className="column sdgTopTable">
                        <Table className="TopIndSDG_table" responsive="sm">
                            <thead>
                                <tr>
                                    <th>SDG</th>
                                    <th>Industry (Cont.)</th>
                                    <th className="TopIndSDG_th">Average envSDG Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg10} /></td>
                                    <td>Manufacturing-31</td>
                                    <td>100.00%</td>
                                </tr>
                                <tr>
                                    <td>Professional Services</td>
                                    <td>87.50%</td>
                                </tr><tr>
                                    <td>Transportation</td>
                                    <td>87.50%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg11} /></td>
                                    <td>Extractives</td>
                                    <td>43.25%</td>
                                </tr>
                                <tr>
                                    <td>Transportation</td>
                                    <td>41.78%</td>
                                </tr>
                                <tr>
                                    <td>Construction</td>
                                    <td>41.11%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg12} /></td>
                                    <td>Transportation</td>
                                    <td>42.47%</td>
                                </tr>
                                <tr>
                                    <td>Extractives</td>
                                    <td>40.25%</td>
                                </tr><tr>
                                    <td>Construction</td>
                                    <td>39.23%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg13} /></td>
                                    <td>Extractives</td>
                                    <td>57.35%</td>
                                </tr>
                                <tr>
                                    <td>Transportation</td>
                                    <td>56.74%</td>
                                </tr>
                                <tr>
                                    <td>Utilities</td>
                                    <td>50.95%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg14} /></td>
                                    <td>Extractives</td>
                                    <td>50.88%</td>
                                </tr>
                                <tr>
                                    <td>Construction</td>
                                    <td>49.91%</td>
                                </tr><tr>
                                    <td>Transportation</td>
                                    <td>35.51%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><SdgGoal cl="sdgTable" src={Sdg15} /></td>
                                    <td>Exctractives</td>
                                    <td>52.76%</td>
                                </tr>
                                <tr>
                                    <td>Construction</td>
                                    <td>49.70%</td>
                                </tr>
                                <tr>
                                    <td>Transportation</td>
                                    <td>28.55%</td>
                                </tr>
                                <tr className="TopIndSDG_tr_t">
                                    <td rowSpan="3"><div className="topIndSDG_Overall"><span>Overall</span><span>envSDG</span></div></td>
                                    <td>Extractives</td>
                                    <td>44.37%</td>
                                </tr>
                                <tr>
                                    <td>Construction</td>
                                    <td>42.30%</td>
                                </tr>
                                <tr>
                                    <td>Transportation</td>
                                    <td>37.71%</td>
                                </tr>
                            </tbody>
                        </Table></div></div>
                <div>

                </div>
            </div>
        );
    }

}

export default TopIndSDG;