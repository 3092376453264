import React, {Component} from "react";
import '../Stylesheets/NoCompanyFound.scss'

class NoCompanyFound extends Component {

    render() {
        return(
            <div> No Company with ID <span className="searchTermNotFound"> {this.props.searched} </span>found. You may use the search below to look up another company. If you think there's been a mistake, please contact our support at tech@csr.org</div>
        );
    }
}

export default NoCompanyFound;