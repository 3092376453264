import React, { Component } from "react";
import TopIndSDG from '../Components/TopIndSDG';

import SDGTiles from '../Components/SDGTiles';


class TopIndSDGPage extends Component {
    componentDidMount() {

    }
    render() {
        return (
            <div>
                <SDGTiles />
                <hr />
                <TopIndSDG />
                <hr /><div className="chart_footnote">
                </div>
            </div>
        )
    }
}

export default TopIndSDGPage;