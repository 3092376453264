
import React, {Component} from "react";


class ReportsPage extends Component {
    render() {
        return (
            <div className="content">
    
            <div className="field field--name-body field--type-text-with-summary field--label-hidden field--items">
                    <div property="schema:text" className="field--item"><h2 className="nav_anchor" id="h2_top" title="Reports &amp; Publications">Reports &amp; Publications</h2>
      
      <p>Here you will find all our publications available for download including methodology information, specific industry reports, and reports from previous editions of the CSR-S Monitor, as well as citations for academic research publications that utilize the CSR-S Monitor data.</p>
      
      <hr /><h2 className="nav_anchor" id="h2_methodology" title="Methodology">CSR-S Monitor Scoring Methodology</h2>
      
      <p> </p>
      
      <p><font face="Times New Roman, serif"><font size="3"><font>The CSR-S Monitor uses a content analysis approach to score CSR reports. The CSR-S Monitor scoring rubric categorizes the content of each CSR report into 11 components called “Contextual Elements,” which cover the most common CSR topics. Within the elements, the report is scored based on scope of coverage and depth of information, as well as the degree of external verification of the data.</font></font></font></p>
      
      <p><font face="Times New Roman, serif"><font size="3"><font>The information provided in this section offers insight into the CSR-S Monitor scoring methodology. First, descriptions of the 11 Contextual Elements, including operational definitions and a list of illustrative sub-elements and key topics for each are provided. Second, one specific scoring criterion in the Environment Element, including examples from real reports is included. Third, sample scorecards for two different companies, including information about various rankings.</font></font></font></p>
      
      <ul><li><a href="/sites/default/files/pdfs/contextual_elements.pdf">Contextual Elements</a></li>
          <li><a href="/sites/default/files/pdfs/environment_criterion_examples.pdf">Environment Element</a></li>
          <li><a href="/sites/default/files/pdfs/sample_scorecard.pdf">Sample Scorecards</a></li>
      </ul><p>For more information, refer to the <a href="/sites/default/files/pdfs/2018 CSR-S Monitor Report.pdf">CSR-S Monitor Report</a>; for all scoring data, refer to the <a href="/analytics">Analytics</a> section; and for a closer look at the data and trends from companies in some of the standout industries, refer to the <a href="#h2_industry">Industry Reports</a> section.</p>
      
      <p> </p>
      
      <hr /><h2 className="nav_anchor" id="h2_industry" title="Industry Reports">Industry Reports</h2>
      
      <p><font face="Times New Roman, serif"><font size="3"><font>The CSR-S Monitor publishes a series of industry reports, taking a closer look at the findings from certain industry groups. The first reports are on the Extractive Industry (NAICS 21) and Finance &amp; Insurance (NAICS 52) using data from the 2014 edition of the Monitor. </font></font></font></p>
      
      <ul><li><a href="/sites/default/files/pdfs/extractive_indsutry_naics_21_report.pdf">Extractive Industry (NAICS 21)</a> (2014)</li>
          <li><a href="/sites/default/files/pdfs/finance_industry_naics_52_report.pdf">Finance &amp; Industry (NAICS 52)</a> (2014)</li>
      </ul><hr /><h2 className="nav_anchor" id="h2_archive" title="Archives">Archives</h2>
      
      <h4>2016</h4>
      
      <ul><li><a href="/sites/default/files/pdfs/2016-Edition CSR-S Monitor Report.pdf">(2016) CSR-S Monitor Report</a></li>
      </ul><h4>2014</h4>
      
      <ul><li><a href="/sites/default/files/pdfs/csr-s_monitor_2014_report.pdf">(2014) CSR-S Monitor Report</a></li>
      </ul><h4>2012</h4>
      
      <ul><li><a href="/sites/default/files/pdfs/csr-s_monitor_2012_report.pdf">(2012) CSR-S Monitor Report</a></li>
          <li><a href="/sites/default/files/pdfs/csr-s_monitor_2012_company_overall_rankings.pdf">(2012) CSR-S Monitor Company Standings</a></li>
      </ul><hr /><h2 className="nav_anchor" id="h2_apublications" title="Academic Publications">Academic Publications</h2>
      
      <ul><li><a href="http://onlinelibrary.wiley.com/doi/10.1111/basr.12113/full" target="_blank" rel="noreferrer">Sethi, S. P., Rovenpor, J. L., &amp; Demir, M. (2017). Enhancing the quality of reporting in corporate social responsibility guidance documents: The roles of ISO 26000, Global Reporting Initiative and CSR-Sustainability Monitor. Business and Society Review, 122(2), 139-163.</a></li>
          <li><a href="https://link.springer.com/article/10.1007/s10551-015-2878-8" target="_blank" rel="noreferrer">Sethi, S. P., Martell, T. F., &amp; Demir, M. (2017). An evaluation of the quality of corporate social responsibility reports by some of the world’s largest financial institutions. Journal of Business Ethics, 140(4), 787-805.</a></li>
          <li><a href="https://link.springer.com/article/10.1007/s10551-015-2862-3" target="_blank" rel="noreferrer">Sethi, S. P., Martell, T. F., &amp; Demir, M. (2017). Enhancing the role and effectiveness of corporate social responsibility (CSR) reports: The missing element of content verification and integrity assurance. Journal of Business Ethics, 144(1), 59-82.</a></li>
          <li><a href="https://link.springer.com/article/10.1057/s41299-016-0004-1" target="_blank" rel="noreferrer">Sethi, S. P., Martell, T. F., &amp; Demir, M. (2016). Building Corporate Reputation Through Corporate Social Responsibility (CSR) Reports: The Case of Extractive Industries. Corporate Reputation Review, 19(3), 219-243.</a></li>
          <li><a href="http://www.academic-bookshop.com/ourshop/prod_4210027-ACSEAR-2015-14th-Australian-Centre-on-Social-and-Environmental-Accounting-Research-Conference-Sydney-Australia-ISBN-9781910810798.html" target="_blank" rel="noreferrer">Sethi, P., Martell, T., &amp; Demir, M. (2015, November). CSR Monitor–A Novel Instrument for Evaluating the Quality of CSR Reports. In A-CSEAR 2015-Proceedings of the 14th Australasian Centre on Social and Environmental Accounting Research Conference: A-CSEAR 2015 (p. 146). Academic Conferences and publishing limited.</a></li>
          <li><a href="http://www.emeraldinsight.com/doi/full/10.1108/CG-10-2013-0112" target="_blank" rel="noreferrer">Sethi, P. (2014). The Wal-Mart affair–where implausible deniability is the coin of the realm. Corporate Governance, 14(3), 424-451.</a></li>
          <li>Sethi, S. P. (2013). The World of Wal-Mart. Carnegie Council For Ethics In International Affairs, <a href="http://www.carnegiecouncil.org/publications/ethics_online/0081.html" target="_blank" rel="noreferrer">http://www.carnegiecouncil.org/publications/ethics_online/0081.html.</a></li>
          <li>Sethi, S. P. (2012). Two Faces of Apple. Carnegie Council For Ethics In International Affairs, <a href="http://www.carnegiecouncil.org/publications/ethics_online/0068.html" target="_blank" rel="noreferrer">http://www.carnegiecouncil.org/publications/ethics_online/0068.html.</a></li>
      </ul></div>
                </div>
        
        </div>
        )
    }
}

export default ReportsPage;