
import React, { Component } from "react";
import FlowOMatic from '../Components/FlowOMatic';

import SDGTiles from '../Components/SDGTiles';
import '../Stylesheets/FlowOMaticPage.scss';

class FlowOMaticPage extends Component {
    render() {
        return (
            <div>
                <h3>Methodology Overview</h3><hr />
                <div>
                    <p>The foundation of the SDG Disclosure Monitor is the mapping of company scores on our other project, the <a href="https://www.csrsmonitor.org" target="_blank" rel="noreferrer">CSR-S Monitor®</a> to the targets and subsequently Goals of the SDGs.</p>
                    <p>The first subject we mapped is the environment. We began by isolating the environmentally-related aspects of the SDGs using the list of targets defined by the <a href="https://www.unep.org/" target="_blank" rel="noreferrer">United Nations Environment Programme</a> (dropping a few that were too narrowly-focused for our purposes), which gave us 46 targets across 13 of the 17 Goals. Next, we identified which of the CSR-S Monitor environment topics was referenced by each of those 46 targets.</p><p>For example, we classified the first target of SDG 14 (“14.1”), which states:
                       </p>
                    <p className="methodology_quote">By 2025, prevent and significantly reduce marine pollution of all kinds, in particular from land-based activities, including marine debris and nutrient pollution.</p>
                    <p>…as referencing the “Waste Management” and “Biodiversity” topics. In total, we found that the 46 targets discussed issues variously related to 15 of the CSR-S Monitor environment topics. These references, visible below in the Methodology Map chart, form the basis of our scoring system for the CSD Disclosure Monitor. Note that SDGs 4, 5, 16, and 17 are excluded as they do not have environmentally-related targets that we could score.</p>
                    <br />
                    <FlowOMatic />

                    <p>To translate scores on CSR-S Monitor environment topics to scores on a particular SDG we used what we call the “Topic Adjustment Factor.” Essentially, an individual envSDG score is a weighted average of the company’s scores on the CSR-S Monitor topics that are referenced by targets within that Goal, with greater weight given to topics that are referenced by a higher percentage of the environmentally-related targets. For example, a topic that is referenced by three out of six environmentally-related targets in a Goal has an Adjustment Factor of 0.5. By multiplying a company’s score on a particular topic by its Adjustment Factor for that Goal, then summing all the relevant products together and dividing by the maximum achievable score, we arrive at a percentage for the company’s score on that particular Goal. We then repeat this process for each of the 13 SDGs that contain environmentally-related targets.</p>
                    <p>
                        Finally, a company’s Overall envSDG score is similarly a weighted average of its individual envSDG scores. This time the weights are determined by what we call the Goal’s “Environmental Intensity Factor,” which is defined as how many targets within that Goal are environmentally-related out of the total number of 46 targets. For example, Goal 6 has five environmentally-related targets, so its Intensity Factor is 5 / 46 or 10.87%. Multiplying a company’s individual envSDG scores by their respective Intensity Factors and summing the products gives us our final value, the company’s Overall envSDG score.
                    </p>
                </div>
                <SDGTiles />
                <hr />

                <br />
                {/* <div className="chart_footnote">
                    <ul><li>We use the <a href="https://www.census.gov/eos/www/naics/" target="_blank" rel="noreferrer">North American Industry Classification System (NAICS)</a> for industry definitions, with companies labeled by <a href="https://www.gale.com/c/business-insights-essentials" target="_blank" rel="noreferrer">GALE Business Insights: Essentials database</a>.</li>
                    </ul>
                    *  The following SDGs do not contain environment related topics and therefore are not mapped:
                <ul>
                        <li>4 - Quality Education</li>
                        <li>5 - Gender Equality</li>
                        <li>16 - Peace, Justice, & Strong Institutions</li>
                        <li>17 - Partnerships for the Goals</li>
                    </ul>
                </div> */}
                <p>
                </p>
                <div className="bottomSpacerDiv"></div>
            </div >
        )
    }
}

export default FlowOMaticPage;