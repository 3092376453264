
import React, { Component } from "react";


class AnalyticsPage extends Component {
    render() {
        return (
            <div>
                <h3>Rankings from the CSR-S Monitor</h3>
                <hr />
                <p>Corporate social responsibility (CSR) disclosure has emerged to complement financial disclosure and thereby provide a more complete picture of corporate performance. A growing number of companies report on environmental and social risks and opportunities associated with their businesses. While this increases the information available, wide variation in the scope and quality of CSR reports poses significant challenges to analysts, investors and other interested parties.</p>

                <p>The Corporate Social Responsibility-Sustainability (CSR-S) Monitor® aims to address this variation in reporting and offers a content-based framework for the analysis of CSR reports. The Monitor provides a comprehensive CSR report database containing systematic evaluations of reports published by the world’s largest companies. Scores reflect the quality of information disclosed based on a set of the 11 most common areas of corporate responsibility covered in these reports.</p>

                <p>This interactive website allows readers to view data from all reports across the three most recent editions of the Monitor, 2014, 2016, and 2019. Through the links above the reader can browse the full list of companies or narrow their search to a specific industry or to a region (by location of company headquarters).</p>

                <p>Selecting a company from one of these lists (or typing a company name into the search bar) will take the reader to that company’s profile page. Here the reader will find background information for each company, including the location of their headquarters, the name of their primary industry/North American Industry Classification System (NAICS) code, and secondary NAICS code(s), if applicable. Below the background information is the Report Summary table, which contain the company’s disclosure scores on each Contextual Element, as well as the overall median scores for all companies and the median scores of the company’s primary industry, for comparison. In addition, a user-selectable third column allows the reader to compare the company with any other in the database, as well as any other industry or region represented.</p>

                <p>For more detailed information about the methodology, and some examples of the type of findings that can be drawn from the data, the reader can download our CSR-S Monitor reports.
                </p>
                <hr />
                <h3>Top 25</h3>
                <hr />
                <h3>All Rankings</h3>
                <hr />
                <h3>Reports by Region</h3>
                <hr />
                <h3>Reports by Industry</h3>
                <hr />
            </div>
        )
    }
}

export default AnalyticsPage;