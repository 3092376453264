
import React, { Component } from "react";
import D3BarChart from '../Components/D3BarChart';

import SDGTiles from '../Components/SDGTiles';


class D3BarChartPage extends Component {
    render() {
        return (
            <div>
                <h3>Overall CSD Disclosure Findings</h3>
                <hr />
                <div>
                    <ul>
                        <li>
                            SDG #10 (Reduced Inequalities) is the SDG on which companies in our sample score highest. It covers largely universal topics such as climate change and environmental partnerships, and companies across the board show a reasonably high level of awareness with regard to these topics.*
                        </li>
                        <li>
                            SDG #7 (Affordable and Clean Energy) and SDG #13 (Climate Action) are the second and the third highest scoring SDGs. SDGs #7 and #13 refer to some of the core areas of environmental sustainability (e.g., renewable energy use, emissions, toxic spills, and climate change) and thus are two of the most noteworthy goals.
                        </li>
                        <li>
                            On the flip side, SDG #15 (Life On Land) is the lowest scoring SDG, followed by SDG#8 (Decent Work and Economic Growth) and SDG #14 (Life Below Water) among the 13 SDGs that contain environmentally-focused targets.
                        </li>
                        <li>
                            See the Executive Summary report (hyperlink: TBD) for more findings and discussions.
                        </li>
                    </ul>
                </div>
                <br /><br />
                <p>The following chart shows the average envSDG scores and standard deviations for all companies in our sample.¹</p>
                <hr />
                <D3BarChart width={800} height={500} />
                <div className="chart_footnote">
                    Footnote 1: Note that SDGs 4, 5, 16, and 17 are excluded as they do not have environmentally-related targets that we could score
                </div><br />
                <SDGTiles />
                <br />
            </div>
        )
    }
}

export default D3BarChartPage;